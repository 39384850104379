import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {  payment1, payment2, payment3, payment4, payment5, payment6 } from '../../assests'
const PaymentStep4 = ({setPay4}) => {
  return (
    <div>
            {/* <!-- Add Payment Step 4 --> */}
            <div className="modals show" id="add-payment">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
       
            <div className="add-payemnt-step4" >
          <div className="modal-header text-center d-block border-0">
              <h2>Make a Payment</h2>
              <div className="small-devider mb-2"></div>
              <p>Choose Payment Type</p>
          
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          </div>
          <div className="modal-body bg-white">
              <div className="popup-form">
                  <div className="row ml-0 mr-0">
                      <div className="col-12 col-md-12">
                        <div className="card shadow-sm">
                          <div className="card-body">
                            <div className="payment-cards">
                              <Link href="#"><img src={payment1} alt="" /></Link>
                              <Link href="#"><img src={payment2} alt="" /></Link>
                              <Link href="#"><img src={payment3} alt="" /></Link>
                              <Link href="#"><img src={payment4} alt="" /></Link>
                              <Link href="#"><img src={payment5} alt="" /></Link>
                              <Link href="#"><img src={payment6} alt="" /></Link>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 mt-3">
                        
                        <div className="add-card text-center mt-3 mb-3">
                          <Link onClick={()=>setPay4(false)} href="#" className="btn btn-dark cancel-payment">Cancel</Link>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default PaymentStep4