import logo from './logo.svg';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';
import GetNewNumber from './pages/GetNewNumber';
import ManagePhoneNum from './pages/ManagePhoneNum';
import EditActionModal from './pages/ManagePhoneNum/EditActionModal';
import RingTo from './pages/ManagePhoneNum/RingTo';
import AccountInfo from './pages/AccountInfo';
import CallHistory from './pages/CallHistory';
import Billing from './pages/Billing';
import MessageCenter from './pages/MessageCenter';
import ManageUsers from './pages/ManageUsers';
import AddNewUser from './pages/AddNewUser';
import EditNewUser from './pages/EditUser';
import EditUser from './pages/EditUser';


function App() {
  let routes = [
    { path: "/", element: <HomePage /> },
    { path: "/login", element: <LoginPage /> },
    { path: "/dashboard", element: <Dashboard /> },
    {path:"/getNewNumber", element:<GetNewNumber />},
    {path:"/managephone", element:<ManagePhoneNum />},
    {path:'/accountInfo', element:<AccountInfo/>},
    {path:'/callHistory', element:<CallHistory/>},
    {path:'/billing', element:<Billing/>},
    {path:'/messageCenter', element:<MessageCenter/>},
    {path:'/manageUser', element:<ManageUsers/>},
    {path:'/AddnewUser', element:<AddNewUser/>},
    {path:'/editUser', element:<EditUser/>},
   
    { path: "*", element: <Navigate to="/" replace /> },
  ];
  return (
    <BrowserRouter>
    {/* <Layout> */}
      <Routes>
        {routes?.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    {/* </Layout> */}
  </BrowserRouter>
  );
}

export default App;
