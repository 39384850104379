import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {  Echeck, Lockk, PayCards } from '../../assests'
import PaymentStep2 from './PaymentStep2.js'

const PaymentMethodModal = ({setPaymentModal}) => {
    const [pay2,setPay2]=useState(false)
    
    

    let Paymentmethod1=()=>{
        setPay2(!pay2)    
        
    }
    
    
    
  return (
    <div>
        <div className="modals" id="add-payment">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">

        {/* <!-- Add Payment Step 1 --> */}

        <div className="add-payemnt-step1">
        {/* <!-- Modal Header --> */}
            <div className="modal-header text-center d-block border-0">
                <p><img src={Lockk} alt="" style={{width: "12px"}} /> Secured with 128-bit SSL Encryption. You’re safe</p>
            <h4 className="modal-title text-uppercase">Add Payment Method</h4>
            <div className="small-devider mb-2"></div>
            
            <button type="button" className="close" data-dismiss="modal" onClick={()=>setPaymentModal(false)} >&times;</button>
            </div>
  
        {/* <!-- Modal body --> */}
            <div className="modal-body bg-white">
            
                <div className="payment-type-info mb-4">
                    <div className="pay-type-box">
                        <div className="pay-type-name">e-Check</div>
                        <div className="pay-icn"><img src={Echeck} alt="" /></div>
                    </div>
                    <div className="loc-select">
                        <label>United States</label>
                        <select className="form-control">
                            <option>United States</option>
                            <option>United Kingdom</option>
                        </select>
                    </div>
                </div>
                <hr/>
                <div className="payment-cards mt-4">
                    <h4>Credit or Debit Cards </h4>
                    <div className="cards-list">
                        <Link onClick={Paymentmethod1} href="#" className="add-payment"><img src={PayCards} alt="" /></Link>
                    </div>
                
            </div>
            </div>
        </div>
      
      </div>
    </div>
  </div>

  {
    pay2 && 
    <PaymentStep2 setPay2={setPay2}/>
  }
    </div>
  )
}

export default PaymentMethodModal