import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { add, Addcall, delCall, editCall, gear, video } from '../../assests'
import RingTo from './RingTo'

const EditActionModal = ({setEditModal}) => {

    const [showRingTo,setShowRingTo]=useState(false)
  return (
    <div><div className="modals show" id="edit-action">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
  
        {/* <!-- Modal Header --> */}
        <div className="modal-header text-center d-block border-0">
          <h4 className="modal-title text-uppercase">CALL FORWArDING</h4>
          <div className="small-devider mb-2"></div>
          
          <div className="backto-step1 back-btn">
            <Link href="#"><i className="fa fa-exchange mr-2" aria-hidden="true"></i> Change Type Of Call Action</Link>
        </div>
          <button type="button" className="close" data-dismiss="modal" onClick={()=>setEditModal(false)}>&times;</button>
        </div>
  
        {/* <!-- Modal body --> */}
        <div className="modal-body bg-white">
          <div className="row ml-0 mr-0 align-items-center">
            <div className="col-12 col-lg-8">
              <div className="form-group">
                <label>Description</label>
                <input type="text" className="form-control" placeholder="Call Alan"/>
              </div>
            </div>
            <div className="col-12 col-lg-2">
              <div className="form-group">
                <label>Extension</label>
                <input type="text" className="form-control" placeholder="101"/>
              </div>
            </div>
            <div className="op-txt">
              <div className="form-group mb-0">
                <span>(optional)</span>
               </div>
            </div>
           
          </div>
          <div className="row ml-3 mr-3">
            <div className="ml-auto">
              <Link href="#" className="text-dark"><img src={video} alt="" className="mr-1"/> View Tutorial</Link>
            </div>
          </div>
          <div className="row ml-0 mr-0 mt-4">
            <div className="col-12 col-lg-12">
              <div className="table-responsive">
                <table className="table user-history table-striped">
                <thead>
                  <tr>
                    <th>Ring Delay <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span></th>
                    <th>Call Forwarding Destination <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><p>No delay, immediately ring</p></td>
                    <td>
                      <div className="form-group">
                        <select name="" id="" className="form-control">
                          <option>18185882405 Ring-to to alan's phone</option>
                        </select>
                      </div>
                      <div className="btns-cont">
                        <button type="button" onClick={()=>setShowRingTo(!showRingTo)} className="btn btn-labeled btn-light border bg-light border-secondary text-dark py-1 px-3 mr-3 text-uppercase" data-toggle="modal" data-target="#ring-to-modal" >
                          <img src={editCall} alt="" className="pr-2"/>Edit Ring-to
                        </button>
                        <button type="button" className="btn btn-labeled btn-light bg-white border border-secondary text-dark py-1 px-3 mr-3 text-uppercase" >
                          <img src={Addcall} alt="" className="pr-2"/>Create New Ring-To
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div className="text-center">
                        <button type="button" className="btn btn-labeled btn-number text-uppercase">
                          <span className="btn-label2 mr-2"><img src={add} alt=""/></span>Create Call Action / Extension
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row ml-3 mr-3 justify-content-between callAction-settings-action">
            <div className="col1">
              <label><input type="checkbox" name="" id="" className="mr-1"/>Enable Voicemail2MyEmail<sup>TM</sup> <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span></label>
            </div>
            <div className="col1">
              <Link href="#" className="text-dark"><img src={gear} alt="" /> Show Advanced Settings</Link>
            </div>
          </div>
          <div className="text-center mt-4">
            <Link href="#" className="btn del-call-btn"><img src={delCall} alt="" /> Delete Call alan</Link>
          </div>
        </div>
  
        {/* <!-- Modal footer --> */}
        <div className="modal-footer justify-content-center border-0">
          <button type="submit" className="btn btn-orange px-4">Save</button>
          <button type="button" className="btn btn-dark px-4" data-dismiss="modal" onClick={()=>setEditModal(false)}>Cancel</button>
        </div>
  
      </div>
    </div>
  </div>
  {
    showRingTo &&
    <RingTo 
    setShowRingTo={setShowRingTo}
    />
  }
  </div>
  )
}

export default EditActionModal