import React from "react";
import { Link } from "react-router-dom";
import { logoFooter, fb, twitter, linkedin } from "../../../assests";
import footer_data from "./mock.json";
const Footer = () => {
  return (
    <div>
      <footer className="main-footer">
        <div className="container-fluid px-10">
          <div className="footer-links-cont">
            <div className="footer-logo-cont">
              <Link href="#">
                <img src={logoFooter} alt="" />
              </Link>
              <p className="footer-abt mt-4">
                Founded in 2002, TollFreeForwarding.com is an international
                telecommunications provider based in Los Angeles, California.
              </p>
              <div className="socio-links">
                <Link href="#">
                  <img src={fb} alt="" />
                </Link>
                <Link href="#">
                  <img src={twitter} alt="" />
                </Link>
                <Link href="#">
                  <img src={linkedin} alt="" />
                </Link>
              </div>
            </div>
            {footer_data?.map(({ heading, menu }, index) => {
              return (
                <div className="footer-nav" key={index}>
                  <h3>{heading}</h3>
                  <div className="footer-nav-cont">
                    <ul>
                      {menu?.map(({ link, title }) => {
                        return (
                          <li>
                            <Link to={link}>{title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="copyright text-center mt-5">
            <p>© 2022 TollFreeForwarding.com. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
