import React from 'react'
import {footerLogo2} from "../../assests"
import { Link } from "react-router-dom";
const Footersmall = () => {
  return (
    <div>
        <footer className="main-footer mt-5">
      <div className="container-fluid px-10">
        <div className="footer-links-cont row px-5">
          <div className="footer-logo-cont col-lg-6">
            <Link href="#"><img src={footerLogo2} alt="" /></Link>
          </div>
          <div className="col-lg-6 float-righ text-right">
            <Link href="#" className="pr-5" style={{color: "#888"}}>
              Become a Master Agent
            </Link>
            |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link href="#" style={{color:"#888"}}> Terms & Conditions</Link>

            <p style={{color: "#888"}}>
              © 2022 TollFreeForwarding.com. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
    </div>
  )
}

export default Footersmall