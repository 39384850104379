import React from 'react'
import { Link } from 'react-router-dom'
import { Permission } from '../../assests'
import { PermissionList } from '../../assests/mock_data/array'
import Layout from '../../components/Layout/Layout'

const AddNewUser = () => {
  return (
    <div><Layout>
        <div className="main-wrapper">
      <div className="container-fluid px-12 py-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create New User
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-lg-12">
            <h2 className="p-2 mb-3 text-uppercase">Create New User</h2>
          </div>
          <div className="col-lg-9">
            
            
              {/* <!-- Tabs nav --> */}
              <div className="card shadow-sm">
                <div className="card-body py-5">
                    <h4>User Details</h4>
                    <div className="small-devider mb-2"></div>
                    <div className="row ml-0 mr-0">
                        <div className="col-12 col-lg-12 p-0">
                            <div className="fax-mail-panel billing-panel shadow-sm">
                                
                                <div className="content-body">
                                    <div className="row">
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Status</label>
                                                <select name="" id="" className="form-control">
                                                    <option>Active</option>
                                                    <option>Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> First Name</label>
                                                <input type="text" className="form-control" placeholder="First Name"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Last Name</label>
                                                <input type="text" className="form-control" placeholder="Last Name"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> UserName</label>
                                                <input type="text" className="form-control" placeholder="UserName"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Password <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span></label>
                                                <input type="password" class="form-control" placeholder="Passwod"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Email Address</label>
                                                <input type="text" className="form-control" placeholder="Email Address"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Time Zone</label>
                                                <select name="" id="" className="form-control">
                                                    <option>Select Time Zone</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Extension</label>
                                                <input type="text" className="form-control" placeholder="Extension"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Address Line 1</label>
                                                <input type="text" className="form-control" placeholder="Address Line 1"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Address Line 2</label>
                                                <input type="text" className="form-control" placeholder="Address Line 2"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input type="text" className="form-control" placeholder="City"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <select name="" id="" className="form-control">
                                                    <option>Select Country</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>State / Region</label>
                                                <select name="" id="" className="form-control">
                                                    <option>State / Region</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Postal Code</label>
                                                <input type="text" className="form-control" placeholder="Postal Code"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <input type="submit" name="" id="" className="btn btn-orange" value="Save"/>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                
              </div>
            

            {/* <!-- Tabs content --> */}
          </div>
          <div className="col-lg-3">
            <div className="right-sidebar bg-white">
              <div className="d-flex">
                <h5 className="pl-3 pr-3 pt-2 pb-2 w-100 rounded">
                  <img src={Permission} alt="" className="w-10 mr-3"/>Permissions
                </h5>
              </div>
              <div className="sidebar-content pt-2 pl-3 pr-3 pb-3">
                <ul className="list-checkboxes p-0">
                {PermissionList.map(({label})=>{
                    return(
                        <li>
                        <label><input type="checkbox" name="" id=""/> {label}</label>
                    </li>


                    )
                })}

                   
                    

                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </Layout></div>
  )
}

export default AddNewUser