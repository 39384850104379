import React from 'react'
import { callIcn1, callIcn2, callIcn3, callIcn4, callIcn5 } from '../../assests'

const NewCall = ({setCreateNew}) => {
  return (
    <div><div className="modals" id="new-callAction">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
  
        {/* <!-- Modal Header --> */}
        <div className="modal-header text-center d-block border-0">
          <h4 className="modal-title text-uppercase">Create New Call Action</h4>
          <div className="small-devider mb-2"></div>
          
          <button type="button" className="close" onClick={()=>setCreateNew(false)}>&times;</button>
        </div>
  
        {/* <!-- Modal body --> */}
        <div className="modal-body bg-white">
          <div className="row ml-0 mr-0 justify-content-center">
            <div className="col-12 col-lg-4 mb-4">
              <div className="card call-action-card">
                <div className="action-icn"><img src={callIcn1} alt="" /></div>
                <div className="card-body">
                  <h4>Voicemail2MyEmail™</h4>
                  <p>Create a greeting for your callers and receive voice messages via email.</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 mb-4">
              <div className="card call-action-card">
                <div className="action-icn"><img src={callIcn2} alt="" /></div>
                <div className="card-body">
                  <h4>Call Forwarding</h4>
                  <p>Forward incoming calls to your existing phones or  SIP(VoIP) addresses, forward to multiple destinations. Voicemail optional.</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 mb-4">
              <div className="card call-action-card">
                <div className="action-icn"><img src={callIcn3} alt="" /></div>
                <div className="card-body">
                  <h4>Voice Menu (IVR)</h4>
                  <p>Prompt your callers with an interactive menu.  Callers can press a key to go to another </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 mb-4">
              <div className="card call-action-card">
                <div className="action-icn"><img src={callIcn4} alt="" /></div>
                <div className="card-body">
                  <h4>Announcement</h4>
                  <p>Play a message to your callers and either continue to another call action or hang up.</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 ">
              <div className="card call-action-card">
                <div className="action-icn"><img src={callIcn5} alt="" /></div>
                <div className="card-body">
                  <h4>Time/Day Schedule</h4>
                  <p>Route calls to other call actions based on time of day and day of the week schedule.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        
  
      </div>
    </div>
  </div></div>
  )
}

export default NewCall