import React, { useState } from "react";
import { Link } from "react-router-dom";

const CreateNewMil = ({ setShow }) => {
  const array = ["Inactive", "Upload New", "Record New", "Active"];
  const [state, setState] = useState("Inactive");
  return (
    // <div>
    <div className="modals" id="voice2mymail">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header text-center d-block border-0">
            <h4 className="modal-title text-uppercase">Voicemail2MyEmail</h4>
            <div className="small-devider mb-2"></div>
            <div className="backto-step1 back-btn">
              <Link href="#">
                <i className="fa fa-exchange mr-2" aria-hidden="true"></i>{" "}
                Change Type Of Call Action
              </Link>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShow(false)}
            >
              &times;
            </button>
          </div>
          <div className="modal-body bg-white">
            <div className="row ml-0 mr-0 align-items-center">
              <div className="col-12 col-lg-8">
                <div className="form-group">
                  <label>Description</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Call Alan"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <label>Extension</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="101"
                  />
                </div>
              </div>
              <div className="op-txt">
                <div className="form-group mb-0">
                  <span>(optional)</span>
                </div>
              </div>
            </div>
            <div className="row ml-3 mr-3">
              <div className="ml-auto">
                <Link href="#" className="text-dark">
                  <img src="images/video.png" alt="" className="mr-1" /> View
                  Tutorial
                </Link>
              </div>
            </div>
            <div className="row ml-0 mr-0 mt-4">
              <div className="col-12 col-lg-12">
                <table className="table user-history table-striped">
                  <thead>
                    <tr>
                      <th>
                        Email New Message Notifications To{" "}
                        <span
                          className="text-success"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="help"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <div className="form-group">
                              <label>
                                {" "}
                                Email Address:{" "}
                                <span
                                  className="text-success"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="help"
                                >
                                  <i className="fas fa-question-circle"></i>
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email Address"
                              />
                              <button
                                type="button"
                                className="btn btn-labeled btn-number text-uppercase mt-3"
                              >
                                <span className="btn-label2 mr-2">
                                  <img src="images/add.png" alt="" />
                                </span>
                                Create Call Action / Extension
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row ml-0 mr-0 mt-4">
              <div className="col-12 col-lg-12">
                <table className="table user-history table-striped">
                  <thead>
                    <tr>
                      <th>
                        Audio Greeting{" "}
                        <span
                          className="text-success"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="help"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="row">
                          <div className="col-12 col-lg-12">
                            <div className="audio-greets-tabs mt-4">
                              <ul className="nav nav-tabs">
                                {array.map((val, index) => {
                                  console.log("state", state);
                                  return (
                                    <li className="active tab-li">
                                      <Link
                                        onClick={() => setState(val)}
                                        className={
                                          val === state ? "active show" : " "
                                        }
                                        data-toggle="tab"
                                        href="#inactive"
                                      >
                                        {val}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>

                              <div className="tab-content">
                                <div
                                  id="inactive"
                                  className={`tab-pane  shadow-none ${
                                    state === "Inactive" ? "active" : " "
                                  }`}
                                >
                                  <div className="form-group">
                                    <label>Play Current Audio Greeting</label>
                                    <div className="audio-player-cont">
                                      <audio controls>
                                        <source
                                          src="test.ogg"
                                          type="audio/ogg"
                                        />
                                        <source
                                          src="test.mp3"
                                          type="audio/mpeg"
                                        />
                                        Your browser does not support the audio
                                        tag.
                                      </audio>
                                      <button className="btn btn-orange">
                                        Download
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  id="uploadNew"
                                  className={`tab-pane  shadow-none ${
                                    state === "Upload New" ? "active" : " "
                                  }`}
                                >
                                  <div className="form-group">
                                    <label>Upload New</label>
                                    <input
                                      type="file"
                                      name=""
                                      id=""
                                      className="form-control"
                                    />
                                  </div>
                                </div>

                                <div
                                  id="recordNew"
                                  className={`tab-pane  shadow-none ${
                                    state === "Record New" ? "active" : " "
                                  }`}
                                >
                                  <div class="form-group">
                                    <label>Record New</label>
                                    <input
                                      type="file"
                                      name=""
                                      id=""
                                      class="form-control"
                                    />
                                  </div>
                                </div>

                                <div
                                  id="active"
                                  className={`tab-pane  shadow-none ${
                                    state === "Active" ? "active" : " "
                                  }`}
                                >
                                  <h4>Active</h4>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                      </tr>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>test.mp3</td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>test2.mp3</td>
                                        </tr>
                                      </tbody>
                                    </thead>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default CreateNewMil;
