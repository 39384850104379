import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { BlFname, BlGlobe, BlLoc, BlPostal, BlState, Lockk } from '../../assests'
import PaymentStep4 from './PaymentStep4'
const PaymentStep3 = ({setPay3}) => {
    const [pay4,setPay4]=useState(false)
    let Paymentmethod3=()=>{
        
        // setPay3(false)
        setPay4(!pay4)
       


    }
  return (
    <div>
         {/* <!-- Add Payment Step 3 --> */}
         <div className="modals show" id="add-payment">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
       
         <div className="add-payemnt-step3" >
          <div className="modal-header text-center d-block border-0">
                <p><img src={Lockk} alt="" style={{width: "12px"}} /> Secured with 128-bit SSL Encryption. You’re safe</p>
          <div className="backto-step2 back-btn" >
              <Link href="#"><i className="fa fa-chevron-left" aria-hidden="true"></i> Use Different Payment Method</Link>
          </div>
          {/* <!--<div className="small-devider mb-2"></div>--> */}
          <div className="account-type">
            <label> <input type="radio" name="acc-type" id=""/> Personal Account</label>
            <label> <input type="radio" name="acc-type" id=""/> Business Account</label>
            <label> <input type="radio" name="acc-type" id=""/> Checking</label>
            <label> <input type="radio" name="acc-type" id=""/> Savings</label>
          </div>
          
          <button type="button" className="close" data-dismiss="modal" onClick={()=>setPay3(false)}>&times;</button>
          </div>
          <div className="modal-body bg-white">
              <div className="popup-form">
                  <div className="row ml-0 mr-0">
                      <div className="col-12 col-lg-6">
                          <div className="card-info">
                              <h4>Bank Information</h4>
                              <div className="small-devider mb-2"></div>
                              <div className="card">
                                  <div className="card-body">
                                      
                                      <div className="form-group">
                                          <div className="row">
                                              <div className="col-12 col-lg-6">
                                                  <div className="form-group mb-lg-0">
                                                      <label>First Name</label>
                                                      <div className="input-icn">
                                                          <span className="_icn"><img src={BlFname} alt="" /></span>
                                                          <input type="text" className="form-control" placeholder="First Name"/>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-12 col-lg-6">
                                                  <div className="form-group mb-0">
                                                      <label>Last Name</label>
                                                      <input type="text" className="form-control" placeholder="Last Name"/>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="d-flex justify-content-between">Routing Number
                                          <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span>
                                        </label>
                                        <div className="input-icn">
                                            <span className="_icn"><strong>#</strong></span>
                                            <input type="text" className="form-control" placeholder="Routing Number (Must be 9 digits)"/>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="d-flex justify-content-between">Account Number
                                          <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span>
                                        </label>
                                        <div className="input-icn">
                                            <span className="_icn"><strong>#</strong></span>
                                            <input type="text" className="form-control" placeholder="Account Number (Include leading 0s)"/>
                                        </div>
                                      </div> 
                                      <div className="form-group">
                                        <label className="d-flex justify-content-between">Verify Account Number
                                          <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span>
                                        </label>
                                        <div className="input-icn">
                                            <span className="_icn"><strong>#</strong></span>
                                            <input type="text" className="form-control" placeholder="Verify Account Number"/>
                                        </div>
                                      </div>  
                                      
                                      <div className="form-group">
                                        <label>Nickname</label>
                                        <input type="text" className="form-control" placeholder="Enter a nickname for your card (optional)"/>
                                      </div>
                            
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="card-info">
                          <h4>Bank Account Address</h4>
                          <div className="small-devider mb-2"></div>
                          <div className="card">
                              <div className="card-body">
                                  <div className="form-group">
                                      <label>Country</label>
                                      <div className="input-icn">
                                          <span className="_icn"><img src={BlGlobe} alt="" /></span>
                                          <select className="form-control">
                                            <option>United States</option>
                                            <option>United Kingdom</option>
                                          </select>
                                      </div>
                                  </div>
                                  <div className="form-group">
                                    <label>Address 1</label>
                                    <div className="input-icn">
                                        <span className="_icn"><img src={BlLoc} alt="" /></span>
                                        <input type="text" className="form-control" placeholder="Address 1"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                  <label>Address 2</label>
                                  <div className="input-icn">
                                      <span className="_icn"><img src={BlLoc} alt="" /></span>
                                      <input type="text" className="form-control" placeholder="Address 2"/>
                                  </div>
                              </div>
                                  <div className="form-group">
                                      <div className="row">
                                          <div className="col-12 col-lg-6">
                                              <div className="form-group mb-lg-0">
                                                  <label>State / Region</label>
                                                  <div className="input-icn">
                                                      <span className="_icn"><img src={BlState} alt="" /></span>
                                                      <input type="text" className="form-control" placeholder="State / Region"/>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-12 col-lg-6">
                                              <div className="form-group mb-0">
                                                  <label>Postal Code</label>
                                                  <div className="input-icn">
                                                      <span className="_icn"><img src={BlPostal} alt="" /></span>
                                                      <input type="text" className="form-control" placeholder="Postal Code"/>
                                                  </div>
                                              </div>
                                          </div>
                                          
                                      </div>
                                  </div>
                                  
                        
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 mt-3">
                        <div className="make-primary text-center">
                          <label><input type="checkbox" name="make-primary" id=""/> Make this my primary billing address.</label>
                        </div>
                        <div className="add-card text-center mt-3 mb-3">
                          <Link onClick={Paymentmethod3 } href="#" className="btn btn-orange add-echeck-btn">Add E-Check</Link>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        </div>
        </div>
        </div>
        {
            pay4 && 
            <PaymentStep4 setPay4={setPay4}/>
        }
    </div>
  )
}

export default PaymentStep3