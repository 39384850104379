import React from 'react'
import { Link } from "react-router-dom";
import { delCall, gear } from '../../assests';
const RingTo = ({setShowRingTo}) => {
  return (
    <div>
        <div className="modals" id="ring-to-modal">
  <div className="modal-dialog modal-lg">
    <div className="modal-content">

      {/* <!-- Modal Header --> */}
      <div className="modal-header text-center d-block border-0">
        <h4 className="modal-title text-uppercase">Ring- To</h4>
        <div className="small-devider mb-2"></div>
       
        <button type="button" className="close" data-dismiss="modal" onClick={()=>setShowRingTo(false)}>&times;</button>
      </div>

      {/* <!-- Modal body --> */}
      <div className="modal-body bg-white">
        <div className="row ml-0 mr-0 align-items-center">
          <div className="col-12 col-lg-12">
            <div className="form-group">
              <label>Description</label>
              <input type="text" className="form-control" placeholder="Ring-to alan's phone"/>
            </div>
          </div>
          
         
        </div>
        
        <div className="row ml-0 mr-0 mt-4">
          <div className="col-12 col-lg-12">
            <table className="table user-history table-striped">
              <thead>
                <tr>
                  <th colspan="2">Send Calls To </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><p><strong>Phone Number</strong></p>
                  <p>A Country code is required</p>
                  <p>Don't use international dialing prefix such as 001, 011</p>
                  <p>Don't use a national dialing prefix</p>
                  </td>
                  <td>
                    <div className="form-group">
                      <select name="" id="" className="form-control">
                        <option>USA/Canda (+1)</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" value="1-818-588-2405"/>
                    </div>
                    <p className="text-muted">United States - Burbank - Burbank Da, CA</p>
                    <p className="text-danger">Important: If this Ring-To number is a fax number, please disable Fax2MyEmail</p>
                    
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <p className="text-danger">Please do not forward this ring-to to your Forwardingtollfree.com. This can cause
                      calls to loop through our network and you will be responsible for any charges that
                     occur from this.</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row ml-3 mr-3 justify-content-between callAction-settings-action align-items-center">
          <div className="col1">
            <Link href="#" className="btn del-call-btn"><img src={delCall} alt="" /> Delete Call alan</Link>
          </div>
          <div className="col1">
            <Link href="#" className="text-dark"><img src={gear} alt="" /> Show Advanced Settings</Link>
          </div>
        </div>
        
      </div>

      {/* <!-- Modal footer --> */}
      <div className="modal-footer justify-content-center border-0">
        <button type="submit" className="btn btn-orange px-4">Save</button>
        <button type="button" className="btn btn-dark px-4" onClick={()=>setShowRingTo(false)}>Cancel</button>
      </div>

    </div>
  </div>
</div>
    </div>
  )
}

export default RingTo