import React from 'react'
import { Link } from 'react-router-dom'
import { userDefault } from '../../assests'
import Layout from '../../components/Layout/Layout'

const EditUser = () => {
  return (
    <div><Layout>
        <div className="main-wrapper">
      <div className="container-fluid px-12 py-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit User
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-lg-12">
            <h2 className="p-2 mb-3 text-uppercase">Edit User</h2>
          </div>
          <div className="col-lg-9">
            
            
              {/* <!-- Tabs nav --> */}
              <div className="card shadow-sm">
                <div className="card-body py-5">
                    <h4>Edit User: Alan arman [info@ecommercepack.com]</h4>
                    <div className="small-devider mb-2"></div>
                    <div className="row ml-0 mr-0">
                        <div className="col-12 col-lg-12 p-0">
                            <div className="fax-mail-panel billing-panel shadow-sm">
                                
                                <div className="content-body">
                                    <div className="row">
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Status</label>
                                                <select name="" id="" className="form-control">
                                                    <option>Active</option>
                                                    <option>Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> First Name</label>
                                                <input type="text" className="form-control" placeholder="First Name" value="Alan"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Last Name</label>
                                                <input type="text" className="form-control" placeholder="Last Name" value="Arman"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> UserName</label>
                                                <input type="text" className="form-control" placeholder="UserName" value="info@forwarding.com"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Password <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span></label>
                                                <input type="password" className="form-control" placeholder="Passwod" value="loremipsum"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Email Address</label>
                                                <input type="text" className="form-control" placeholder="Email Address"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label><span className="text-danger">*</span> Time Zone</label>
                                                <select name="" id="" className="form-control">
                                                    <option>Select Time Zone</option>
                                                    <option selected>(UTC-8) Pacific Time (US & Canada)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Extension</label>
                                                <input type="text" className="form-control" placeholder="Extension"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div class="form-group">
                                                <label>Address Line 1</label>
                                                <input type="text" className="form-control" placeholder="Address Line 1"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Address Line 2</label>
                                                <input type="text" className="form-control" placeholder="Address Line 2"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input type="text" className="form-control" placeholder="City"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <select name="" id="" className="form-control">
                                                    <option>Select Country</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>State / Region</label>
                                                <select name="" id="" className="form-control">
                                                    <option>State / Region</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <label>Postal Code</label>
                                                <input type="text" className="form-control" placeholder="Postal Code"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <input type="submit" name="" id="" className="btn btn-orange" value="Save"/>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                
              </div>
            

            {/* <!-- Tabs content --> */}
          </div>
          <div class="col-lg-3">
            <div class="right-sidebar shadow-none">
              <div class="profile-info-cont text-center p-5 shadow-sm mb-4 bg-white">
                <div class="display-pic mb-4">
                    <img src={userDefault} alt="" />
                </div>
                <div class="_info">
                    <h4>Alan Arman is the administrator for this account</h4>
                </div>
              </div>

              <div class="auth-info p-4 bg-orange text-white shadow-sm">
                <label>Two Factor Authentication <span class="text-white" data-toggle="tooltip" title="" data-original-title="help"><i class="fas fa-question-circle"></i></span></label>
                <select name="" id="" class="form-control">
                    <option value="">Active</option>
                    <option value="">Inactive</option>
                </select>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </Layout></div>
  )
}

export default EditUser