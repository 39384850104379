export const BannerData = [
  {
    id: 1,
    text: "Activate Now! - Start Receiving Calls in 3 Minutes",
  },
  {
    id: 2,
    text: "Answer Calls Anywhere",
  },
  {
    id: 3,
    text: "No Set-up fees",
  },
  {
    id: 4,
    text: "Cancel Anytime",
  },
];

export const howItWork = [
  {
    id: 1,
    title: "Choose Your Number",
    text: "Select a local, national or toll-free number from our vast inventory",
  },
  {
    id: 2,
    title: "Activate",
    text: "Let us handle the sign-up - it takes just 3 minutes",
  },
  {
    id: 3,
    title: "Receive Calls",
    text: "Immediately start receiving calls and servicing your customers",
  },
];
