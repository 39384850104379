import React from "react";
import Navbar from "../../components/organisms/Navbar/Navbar";
import {LoginImg,user,Lock} from "../../assests"
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css"
function LoginPage() {
  const navigate =useNavigate()
  return (
    <div>
      <Navbar />
      <div className={[styles.login_page,styles.main_wrapper].join(" ")}>
        <div className="container">
          <div className="row justify-content-center">
            <div className={[styles.col_md_12 ,"col-lg-12"].join(" ")}>
              <div className="bg-white">
                <div className="d-md-flex">
                  <div className={["text w-100",styles.blue_bg ,"rounded-left d-flex p-4"].join(" ")}>
                    <img src={LoginImg} alt="" className="w-100" />
                  </div>
                  <div className="text w-100 p-5 rounded">
                    <h3 className="mt-4 mb-8 pb-4 text-2xl font-semibold text-gray-700">
                      Login here
                    </h3>

                    <form action="#" className="flex flex-col space-y-5">
                      <div className="flex flex-col space-y-1">
                        <label
                          for="user Name"
                          className="d-flex text-gray-500 border border-gray-300 rounded mb-3"
                        >
                          <img
                            src={user}
                            alt=""
                            width="21"
                            height="23"
                            className="m-3"
                          />
                          <input
                            type="User name"
                            id="username"
                            placeholder="User Name"
                            className="w-100 "
                          />
                        </label>
                      </div>

                      <div className="flex flex-col space-y-1">
                        <label
                          for="Password"
                          className="d-flex text-gray-500 border border-gray-300 rounded "
                        >
                          <img
                            src={Lock}
                            alt=""
                            width="20"
                            height="21"
                            className="m-3"
                          />
                          <input
                            type="password"
                            id="password"
                            placeholder="Password"
                            className="w-100 "
                          />
                        </label>
                      </div>

                      <div className="flex items-center justify-between mt-3 mb-3">
                        <span className="text-left">
                          <input
                            type="checkbox"
                            id="remember"
                            className="w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200"
                          />
                          <label for="remember" className="text-sm ml-1 ">
                            Remember me
                          </label>
                        </span>
                        <label for="" className="float-right">
                          <Link href="#" className="text-sm hover:underline">
                            Forgot Password?
                          </Link>
                        </label>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="form-control btn btn-orange pt-2 pb-2 mb-3"
                          onClick={()=>navigate("/dashboard")}
                        >
                          Log in
                        </button>

                        <p className=" text-center mb-1">
                          <span className="text-center">
                            Don't have an account?
                            <Link href="#" className={["underline",styles.sign_up].join(" ")}>
                              Sign Up
                            </Link>
                          </span>
                        </p>
                        <Link href="#" className={[styles.text_gray400]}>
                          Lears More Or Sign up for a free trial.
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
