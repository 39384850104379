import React from 'react'
import { Link } from 'react-router-dom'

const PaymentModifModal = ({setModifModal}) => {
  return (
    <div>
    
        <div className="modals " id="payment-modification">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header text-center d-block border-0">
          
          <h4 className="modal-title text-uppercase">Modify payment settings</h4>
          <div className="small-devider mb-2"></div>
          
          <button type="button" className="close" data-dismiss="modal"onClick={()=>setModifModal(false)}>&times;</button>
        </div>
        <div className="modal-body bg-white p-0">
          <div className="mod-settings1 p-4">
            <div className="row align-items-center">
              <div className="col-12 col-lg-4">
                <div className="mod-setting-field">
                  <label>Recharge Amount: </label>
                  <select className="form-control">
                    <option>Use Eslimated Usage $5.00</option>
                    <option>Use Eslimated Usage $10.00</option>
                    <option>Use Eslimated Usage $15.00</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div className="mod-setting-content">
                  <p>When a charge to your credit card occurs, your account will be charge this amount.</p>
                  <p>To keep your costs low, we ask that you keep this amount at/above your expenceted Monthy</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mod-settings2 p-4">
            <div className="row align-items-center">
              <div className="col-12 col-lg-4">
                <div className="mod-setting-field">
                  <label>When Balance below: </label>
                  <input type="text" className="form-control" placeholder="$ -0.01" />
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div className="mod-setting-content">
                  <p>When a charge to your credit card occurs, your account will be charge this amount.</p>
                  <p>To keep your costs low, we ask that you keep this amount at/above your expenceted Monthy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <div className="mod-settings-btn-cont text-center">
            <Link href="#" className="btn btn-orange">Save</Link>
            <Link href="#" className="btn btn-dark">Cancel</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  
    </div>
  )
}

export default PaymentModifModal