import React from 'react'
import { HistoryModalArray } from '../../assests/mock_data/array'

const HistoryModal = ({setHistoryModal}) => {
  return (
    <div>
        <div className="modals" id="user-history-modal">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header text-center d-block border-0">
          
          <h4 className="modal-title text-uppercase">User Activity History for alan arman 
            (info@ecommercepack.com)</h4>
          <div className="small-devider mb-2"></div>
          <div className="user-date"><i className="fa fa-calendar"></i> Since April 13,2022</div>
          <button type="button" className="close" data-dismiss="modal" onClick={()=>setHistoryModal(false)}>&times;</button>
        </div>
        <div className="modal-body bg-white">
          <table className="table user-history table-striped">
            <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
            {HistoryModalArray.map(({dateTime,description})=>{
                return(
                    <tr>
                    <td>{dateTime}</td>
                    <td>{description}</td>
                </tr>

                )
            })}
                
               
            </tbody>
          </table>
          
          
        </div>
        
      </div>
    </div>
  </div>
    </div>
  )
}

export default HistoryModal