import React from 'react'
import menu from "./menu.json";
import { Link } from "react-router-dom";
import { FaBars, FaPhoneAlt } from "react-icons/fa";
import { logo } from "../../../assests";
const Navbar = () => {
  return (
    <div>
        <header className="main-header py-3">
        <div className="container-fluid px-10">
          <div className="header-cont d-flex justify-content-between align-items-center position-relative">
            <div className="logo-cont">
              <Link href="#">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="menu-toggle show-mob">
              <FaBars />
            </div>
            <div className="nav-section hide-mob">
              <div className="top-nav-cont d-flex justify-content-end align-items-center text-uppercase">
                <div className="call-btn">
                  <Link
                    href="#"
                    className="btn btn-orange d-flex align-items-center"
                  >
                    <FaPhoneAlt className="mr-2" /> Click to call us
                  </Link>
                </div>
                <div className="devider"></div>
                <Link href="#" className="out-number">
                  Outside India?
                </Link>
                <div className="devider"></div>
                <Link
                  href="#"
                  className="ph-number font_public d-flex align-items-center"
                >
                  <FaPhoneAlt className="mr-2 ml-1" /> 000 800 1003 777
                </Link>
              </div>
              <div className="main-nav-cont">
                <ul className="nav-items d-flex">
                  {menu.map((menu, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={menu.path}
                          className={menu.title === "Login" ? "login-btn" : ""}
                        >
                          {menu.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Navbar