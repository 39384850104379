import React , { useState } from 'react'
import { BlCalendar, BlCard, BlFname, BlGlobe, BlLoc, BlPostal, BlState,  Lockk,  } from '../../assests'
import { Link } from 'react-router-dom'
import PaymentStep3 from './PaymentStep3'
const PaymentStep2 = ({setPay2}) => {
    const [pay3,setPay3]=useState(false)
    let Paymentmethod2=()=>{
        setPay3(!pay3)
        // setPay2(false)

    }
  return (
    <div>
         {/* <!-- Add Payment Step 2 --> */}
         <div className="modals" id="add-payment">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
       
         <div className="add-payemnt-step2" >
            <div className="modal-header text-center d-block border-0">
                <p><img src={Lockk} alt="" style={{width: "12px"}} /> Secured with 128-bit SSL Encryption. You’re safe</p>
            <div className="backto-step1 back-btn">
                <Link href="#"><i className="fa fa-chevron-left" aria-hidden="true"></i> Use Different Payment Method</Link>
            </div>
            <div className="small-devider mb-2"></div>
            
            <button type="button" className="close" data-dismiss="modal" onClick={()=>setPay2(false)}>&times;</button>
            </div>
            <div className="modal-body bg-white">
                <div className="popup-form">
                    <div className="row ml-0 mr-0">
                        <div className="col-12 col-lg-6">
                            <div className="card-info">
                                <h4>Card Information</h4>
                                <div className="small-devider mb-2"></div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>Card Number</label>
                                            <div className="input-icn">
                                                <span className="_icn"><img src={BlCard} alt="" /></span>
                                                <input type="text" className="form-control" placeholder="Card Number"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-lg-0">
                                                        <label>First Name</label>
                                                        <div className="input-icn">
                                                            <span className="_icn"><img src={BlFname} alt="" /></span>
                                                            <input type="text" className="form-control" placeholder="First Name"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-0">
                                                        <label>Last Name</label>
                                                        <input type="text" className="form-control" placeholder="Last Name"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-lg-0">
                                                        <label>MM/YY</label>
                                                        <div className="input-icn">
                                                            <span className="_icn"><img src={BlCalendar} alt="" /></span>
                                                            <input type="date" className="form-control" placeholder="MM/YY"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-0">
                                                        <label>Cvv</label>
                                                        <input type="text" className="form-control" placeholder="Cvv"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                          <label>Nickname</label>
                                          <input type="text" className="form-control" placeholder="Enter a nickname for your card (optional)"/>
                                        </div>
                              
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="card-info">
                            <h4>Card Billing Address</h4>
                            <div className="small-devider mb-2"></div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>Country</label>
                                        <div className="input-icn">
                                            <span className="_icn"><img src={BlGlobe} alt="" /></span>
                                            <select className="form-control">
                                              <option>United States</option>
                                              <option>United Kingdom</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                      <label>Address 1</label>
                                      <div className="input-icn">
                                          <span className="_icn"><img src={BlLoc} alt="" /></span>
                                          <input type="text" className="form-control" placeholder="Address 1"/>
                                      </div>
                                  </div>
                                  <div className="form-group">
                                    <label>Address 2</label>
                                    <div className="input-icn">
                                        <span className="_icn"><img src={BlLoc} alt="" /></span>
                                        <input type="text" className="form-control" placeholder="Address 2"/>
                                    </div>
                                </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-lg-0">
                                                    <label>State / Region</label>
                                                    <div className="input-icn">
                                                        <span className="_icn"><img src={BlState} alt="" /></span>
                                                        <input type="text" className="form-control" placeholder="State / Region"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-0">
                                                    <label>Postal Code</label>
                                                    <div className="input-icn">
                                                        <span className="_icn"><img src={BlPostal} alt="" /></span>
                                                        <input type="text" className="form-control" placeholder="Postal Code"/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                          
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 mt-3">
                          <div className="make-primary text-center">
                            <label><input type="checkbox" name="make-primary" id=""/> Make this my primary billing address.</label>
                          </div>
                          <div className="add-card text-center mt-3 mb-3">
                            <Link onClick={Paymentmethod2 } href="#" className="btn btn-orange add-card-btn">Add Card</Link>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        {
            pay3 &&
            <PaymentStep3  setPay3={setPay3}/>
        }

    </div>
  )
}

export default PaymentStep2