import { Link } from "react-router-dom";
import {
  calIcn,
  testLogo1,
  testLogo2,
  testLogo3,
  testLogo4,
  testLogo5,
  testLogo6,
  testLogo7,
  testLogo8,
  testLogo9,
  testLogo10,
  quoteOpen,
  quoteClose,
  callRecord,
  callForward, 
  time, 
  video,
   voiceMail,
    voiceMenu,
    Announcement,
    findGloball,
    callRout,
    voiceMailIcn,
    upDwnArrow,
    wh,
    SearchMail,
    AcName,
    refresh,
    AcWebsite,
    AcUser,
    AcpPass,
    AcPin,
    AcMail,
    AcAccount,
    AcTrial,
    AcCal,
    AcCal2,
    AcStatus,
    QuickLk1,
    QuickLk2,
    QuickLk3,
    QuickLk4,
    dolar,
    AcRecharge,
    AcContact,
    AcPrimary,
    AcTech,
    AcClock,
    AcLoc,
    AcState,
    AcCity,
    AcAdress,
    AcNewsLetter,
    AcOffer,
    AcNotif,
    AcVoice,
    AcCallRoute
} from "..";

export const AdvanceFeatures = [
  {
    id: "1",
    img: calIcn,
    head: "Scheduled Forwarding",
    body: "Robust scheduling options allow you to route your calls based on the days and hours of your business. Route your calls to your main call center during business hours and to voicemail during non-business hours.",
  },
  {
    id: "2",
    img: calIcn,
    head: "Real Time Reporting",
    body: "Instantly view detailed information about calls made to your TollFreeForwarding phone number. Learn about your customers' call habits to help make decisions about outbound sales and follow-up.",
  },
  {
    id: "3",
    img: calIcn,
    head: "Call Recording",
    body: "Robust scheduling options allow you to route your calls based on the days and hours of your business. Route your calls to your main call center during business hours and to voicemail during non-business hours.",
  },
];
export const SliderArray = [
  {
    id: "1",
    img: testLogo1,
  },
  {
    id: "2",
    img: testLogo2,
  },
  {
    id: "3",
    img: testLogo3,
  },
  {
    id: "4",
    img: testLogo4,
  },
  {
    id: "5",
    img: testLogo5,
  },
  {
    id: "6",
    img: testLogo6,
  },
  {
    id: "7",
    img: testLogo7,
  },
  {
    id: "8",
    img: testLogo8,
  },
  {
    id: "9",
    img: testLogo9,
  },
  {
    id: "10",
    img: testLogo10,
  },
];
export const TestiText = [
  {
    id: "1",
    imgOpen: quoteOpen,
    text: "In addition to providing international toll-free phone numbers in 35 countries, their follow-up and constant support was incredibly impressive",
    imgClose: quoteClose,
  },
  {
    id: "2",
    imgOpen: quoteOpen,
    text: "In addition to providing international toll-free phone numbers in 35 countries, their follow-up and constant support was incredibly impressive.",
    imgClose: quoteClose,
  },
  {
    id: "3",
    imgOpen: quoteOpen,
    text: "TollFreeForwarding.com was the only provider we found that could get the preferred national rate phone numbers for us.",
    imgClose: quoteClose,
  },
  {
    id: "4",
    imgOpen: quoteOpen,
    text: "TollFreeForwarding.com is my first stop when wanting to secure new numbers for eHarmony.",
    imgClose: quoteClose,
  },
  {
    id: "5",
    imgOpen: quoteOpen,
    text: "When all our other phone providers failed us, TollFreeForwarding.com was there.",
    imgClose: quoteClose,
  },
  {
    id: "6",
    imgOpen: quoteOpen,
    text: "TollFreeForwarding.com has provided excellent service and we appreciate their efforts, which have benefited our business.",
    imgClose: quoteClose,
  },
  {
    id: "7",
    imgOpen: quoteOpen,
    text: "What I like best about them is the ease of adding new phone numbers online.",
    imgClose: quoteClose,
  },
  {
    id: "8",
    imgOpen: quoteOpen,
    text: "We also were impressed that TollFreeForwarding.com was able to handle the unexpected rush of more than 1,000,000 calls on opening day.",
    imgClose: quoteClose,
  },
  {
    id: "9",
    imgOpen: quoteOpen,
    text: "TollFreeForwarding.com provides a useful, valuable and easy-to-use phone service.",
    imgClose: quoteClose,
  },
  {
    id: "10",
    imgOpen: quoteOpen,
    text: "TollFreeForwarding.com's services and customer support are top notch!  I have been a satisfied TFF customer for 3 years",
    imgClose: quoteClose,
  },
  {
    id: "11",
    imgOpen: quoteOpen,
    text: "TollFreeForwarding.com has always provided us with wonderful customer support for our clients' toll-free services!",
    imgClose: quoteClose,
  },
  {
    id: "12",
    imgOpen: quoteOpen,
    text: "You guys have brilliant service and I highly recommend you!",
    imgClose: quoteClose,
  },
  {
    id: "13",
    imgOpen: quoteOpen,
    text: "With just one quick call to TollFreeForwarding.com, within the hour our new numbers were instantly activated. Simply amazing!",
    imgClose: quoteClose,
  },
  {
    id: "14",
    imgOpen: quoteOpen,
    text: "You guys couldn't have had better timing!! I could wrap up the deal with excellent news and a new number from TollFreeForwarding.com!! Thanks!!!",
    imgClose: quoteClose,
  },
];

export const MyAccount=[
  {
    id:"1",
    title1:"Account Status",
    text1:"On Hold",
    title2:"User Name:",
    text2:"info@ecommercepack.com"
  },
  {
    id:"2",
    title1:"Account Name",
    text1:"Alan arman",
    title2:"Password:",
    text2:"*********"
  },
  {
    id:"3",
    title1:"Account Number:",
    text1:" 7080676",
    title2:"Billing Email:",
    text2:"info@ecommercepack.com"
  },
  {
    id:"4",
    title1:"Call In Pin",
    text1:"715570",
    title2:"Proof of ID:",
    text2:<Link href="upload-document.html">Document</Link>
  },
]
export const HowToSetUp=[
  {
    id:"1",
    icon:callRecord,
    text:"Call Recording",
    img:video
  },
  {
    id:"2",
    icon:callForward,
    text:"Call Forwarding",
    img:video
  },
  {
    id:"3",
    icon:voiceMail,
    text:"Voicemail",
    img:video
  },
  {
    id:"4",
    icon:voiceMenu,
    text:"  Voice Menu (IVR)",
    img:video
  },
  {
    id:"5",
    icon:time,
    text:" Time/Day Schedule",
    img:video
  },
  {
    id:"6",
    icon:Announcement,
    text:" Announcement",
    img:video
  },
]
export const VirtualPhNo=[

  {id:"1",
  text:"View available North America Toll Free - Full Access numbers",
  btn:" Instant Activation"
},
{id:"2",
  text:"View available North America Toll Free - Guam numbers",
  btn:" Instant Activation"
},
{id:"3",
  text:" View available USA/Canada Toll Free (888, 877,866, 855, 844) numbers",
  btn:" Instant Activation"
},
{id:"4",
  text:"View available 800 numbers",
  btn:" Instant Activation"
},

]
export const UsaLocalNumbers=[
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
]
export const InternationalNumbers=[
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
  {
    id:"1",
    place:" USA, Alabama, Birmingham",
    btn:" View"
  },
]
export const Features=[
  {
    id:"1",
    img:findGloball,
    text:"Find-Me/Follow-Me Globally"
  },
  {
    id:"2",
    img:callRout,
    text:"Time-of-Day Call Routing"
  },
  {
    id:"3",
    img:voiceMailIcn,
    text:"Voicemail"
  },
  {
    id:"4",
    img:upDwnArrow,
    text:" Instantly Make changes"
  },
]
export const CallExtension=[
  {
    id:"1",
    extension:" 101 - Call alan",
    name:" 101 - Call alan",
    type:"Call Forwarding",
    typeImg:wh,
    btn:"Edit Settings",
    filter:"View Message",
    filterImg:SearchMail
  },
  {
    id:"2",
    extension:" 101 - Call alan",
    name:" 101 - Call alan",
    type:"Call Forwarding",
    typeImg:wh,
    btn:"Edit Settings",
    filter:"View Message",
    filterImg:SearchMail
  },
  {
    id:"3",
    extension:" 101 ",
    name:"  Call alan",
    type:"Call Forwarding",
    typeImg:wh,
    btn:"Edit Settings",
    filter:"View Message",
    filterImg:SearchMail
  },
  {
    id:"4",
    extension:" 101 - Call alan",
    name:" 101 - Call alan",
    type:"Call Forwarding",
    typeImg:wh,
    btn:"Edit Settings",
    filter:"View Message",
    filterImg:SearchMail
  },
  {
    id:"5",
    extension:" 101 - Call alan",
    name:" 101 - Call alan",
    type:"Call Forwarding",
    typeImg:wh,
    btn:"Edit Settings",
    filter:"View Message",
    filterImg:SearchMail
  },
]
export const AccountDetail=[
  {
    id:"1",
    img:AcName,
    head:"Account Name:",
    text:"Alan arman",
    refresh:refresh
  },
  {
    id:"2",
    img:AcWebsite,
    head:"Website:",
    text:"www.forwardingtollfree.com",
    refresh:" "
  },
  {
    id:"3",
    img:AcUser,
    head:"My Username:",
    text:"info@ecommercepack.com",
    refresh:refresh
  },
  {
    id:"4",
    img:AcpPass,
    head:"My Password:",
    text:"***************",
    refresh:refresh
  },
  {
    id:"5",
    img:AcPin,
    head:"My Call-In PIN:",
    text:"***************",
    refresh:refresh
  },
  {
    id:"6",
    img:AcMail,
    head:"My Email:",
    text:"info@ecommercepack.com",
    refresh:refresh
  },
  {
    id:"7",
    img:AcAccount,
    head:"Account Number:",
    text:"info@ecommercepack.com",
    refresh:" "
  },
  {
    id:"8",
    img:AcStatus,
    head:"Account Status:",
    text:"On Hold",
    refresh:refresh
  },
  {
    id:"9",
    img:AcTrial,
    head:"Trial Status:",
    text:"info@ecommercepack.com",
    refresh:" "
  },
  {
    id:"10",
    img:AcCal,
    head:"Sign Up Date:",
    text:"Sep 19, 2022",
    refresh:" "
  },
  {
    id:"11",
    img:AcCal2,
    head:"Activation Date:",
    text:"Sep 19, 2022",
    refresh:" "
  },
  {
    id:"12",
    img:AcCal,
    head:"Sign Up Date:",
    text:"Sep 19, 2022",
    refresh:" "
  },
  
]
export const AccountSetting=[
  {
    id:"1",
    img:dolar,
    head:"Monthly Minimum:",
    text:"$5.00",
  },
  {
    id:"2",
    img:AcRecharge,
    head:"Recharge Amount:",
    text:"$5.00",
  },
  {
    id:"3",
    img:AcRecharge,
    head:"Recharge At:",
    text:"($0.01)",
  },
]
export const AccountContacts=[
  {
    id:"1",
    img:AcContact,
    head:"Primary Contact Name:",
    text:"alan arman",
    
  },
  {
    id:"2",
    img:AcPrimary,
    head:"Primary Phone:",
    text:"1-818-588-2405",
    
  },
  {
    id:"3",
    img:AcTech,
    head:"Tech Contact Name:",
    text:"John Doe",
   
  },
  {
    id:"4",
    img:AcPrimary,
    head:"Tech Phone:",
    text:"01823649874"
    
  },
  {
    id:"5",
    img:AcMail,
    head:"Tech Email:",
    text:"johndoe@gmail.com",
    
  },
  {
    id:"6",
    img:AcMail,
    head:"Finance Contact Name:",
    text:"Ecompack",
   
  },
  {
    id:"7",
    img:AcPin,
    head:"Finance Phone:",
    text:"0156879546",
   
  },
  {
    id:"8",
    img:AcMail,
    head:"Finance Email:",
    text:"ecom@gmail.com",
    
  },
 
  
]
export const AccountInfom=[
  {
    id:"1",
    img:AcAdress,
    head:"Address Line 1:",
    text:"Please type Address Line 1",
    
  },
  {
    id:"2",
    img:AcAdress,
    head:"Address Line 2:",
    text:"Please type Address Line 2",
    
  },
  {
    id:"3",
    img:AcCity,
    head:"City:",
    text:"Please type Tech contact name",
   
  },
  {
    id:"4",
    img:AcState,
    head:"State:",
    text:"Please type Tech Phone"
    
  },
  {
    id:"5",
    img:AcLoc,
    head:"Postal Code:",
    text:"Please type Tech Email",
    
  },
  {
    id:"6",
    img:AcLoc,
    head:"Country:",
    text:"USA",
   
  },
  {
    id:"7",
    img:AcClock,
    head:" Time Zone:",
    text:"(GMT-8) Pacific Time (US & Canada)",
   
  },
  
 
  
]
export const AccountBilling=[
  {
    id:"1",
    img:AcAdress,
    head:"Address Line 1:",
    text:"Please type Address Line 1",
    
  },
  {
    id:"2",
    img:AcAdress,
    head:"Address Line 2:",
    text:"Please type Address Line 2",
    
  },
  {
    id:"3",
    img:AcCity,
    head:"City:",
    text:"Please type Tech contact name",
   
  },
  {
    id:"4",
    img:AcState,
    head:"State:",
    text:"Please type Tech Phone"
    
  },
  {
    id:"5",
    img:AcLoc,
    head:"Postal Code:",
    text:"Please type Tech Email",
    
  },
  {
    id:"6",
    img:AcLoc,
    head:"Country:",
    text:"USA",
   
  },
  
  
 
  
]
export const EndUser=[
  {
    id:"1",
    img:AcNewsLetter,
    head:"Newsletter",
    
    
  },
  {
    id:"2",
    img:AcOffer,
    head:"Offers",
    
    
  },
  {
    id:"3",
    img:AcNotif,
    head:"Account Notifications",
   
  },
  {
    id:"4",
    img:AcVoice,
    head:"Voicemail and Fax Attachments",
   
    
  },
  {
    id:"5",
    img:AcNotif,
    head:"Maintenance Notifications  ",
   
    
  },
  {
    id:"6",
    img:AcCallRoute,
    head:"Call Routing Changes:",
  
   
  },
  
  
 
  
]
export const QuickLinks=[
  {
    id:"1",
    img:QuickLk1,
    text:"Get a New Number"
  },
  {
    id:"1",
    img:QuickLk2,
    text:"Payment Settings"
  },
  {
    id:"1",
    img:QuickLk3,
    text:"View Call Records"
  },
  {
    id:"1",
    img:QuickLk4,
    text:"Partner With Us!"
  },
]
export const MessageCenterTable=[
  {
    id:"1",
    date:"Date",
    time:"Time",
    caller_Id:"Caller ID",
    number:"Number Dialed",
    type:"Type",
    note:"Note",
    download:"Download"
  },
  
]
export const manageUserActive=[

  {
    id:"1",
    Edit:"Edit",
    History:"History",
    Status:"Active",
    Ext:"",
    LastName:"Arman",
    FirstName:"Alan",
    Username:"ecompack@gmail.com",
    Permissions:"All"
  }
]
export const manageUserInactive=[

  {
    id:"1",
    
    Status:"Inactive",
    Ext:"",
    LastName:"Doe",
    FirstName:"John",
    Username:"John@gmail.com",
    Permissions:"None"
  }
]
export const HistoryModalArray=[

  {
    id:"1",
    dateTime:"Oct. 13 2022 21:55",
    description:"Login"
  
  },
  {
    id:"2",
    dateTime:"Oct. 13 2022 21:55",
    description:"Login"
  
  },
  {
    id:"3",
    dateTime:"Oct. 13 2022 21:55",
    description:"Login"
  
  },
  {
    id:"4",
    dateTime:"Oct. 13 2022 21:55",
    description:"Login"
  
  },
]
export const PermissionList=[
  {
    id:"1",
    label:"All"
  },
  {
    id:"2",
    label:"Home Screen"
  },
  {
    id:"3",
    label:"Add Phone Number"
  },
  {
    id:"4",
    label:" Manage Phone Numbers"
  },
  {
    id:"5",
    label:"Message Center"
  },
  {
    id:"6",
    label:"Billing"
  },
  {
    id:"7",
    label:"Account Information"
  },
  {
    id:"8",
    label:"Call History"
  },
  {
    id:"9",
    label:" Manage Users"
  },
  {
    id:"10",
    label:"Agent"
  },
]