import React from 'react'
import { Link } from 'react-router-dom'
import { block } from '../../assests'

const PaymentModal = ({setshowPayModal}) => {
  return (
    <div>
        {/* <!-- Start Payment Modification Modal--> */}
  <div className="modals" id="manage-block-no">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header text-center d-block border-0">
          
          <h4 className="modal-title text-uppercase">Manage Block Numbers</h4>
          <div className="small-devider mb-2"></div>
          
          <button type="button" className="close" data-dismiss="modal" onClick={()=>setshowPayModal(false)}>&times;</button>
        </div>
        <div className="modal-body bg-white popup-form">
          <div className="text-center">
            <p className="text-danger">
                If possible, copy numbers from the "Caller ID" column on the Call Details page. Do not enter symbols
You can also enter the first part of a number to block a range of numbers that begin with that pattern
Examples. 155555555500000, 5555555500000, 1555500, 1555, anonymous.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
                <div className="form-group">
                    <label>Add Block Number:</label>
                    <div className="input-icn">
                        <span className="_icn"><img src={block} alt=""/></span>
                        <input type="text" className="form-control" placeholder="Add Block Number"/>
                    </div>
                    <p>(You can enter multiple numbers by separating each with a comma)</p>
                  </div>
            </div>
          </div>
          
          
        </div>
        <div className="modal-footer justify-content-center">
          <div className="mod-settings-btn-cont text-center">
            <Link href="#" className="btn btn-orange">Save</Link>
            <Link href="#" className="btn btn-dark">Cancel</Link>
          </div>
        </div>
      </div>
    </div>
  </div>    
  {/* <!-- Start Payment Modification Modal--> */}
    </div>
  )
}

export default PaymentModal