import React from 'react'
import { add } from '../../assests'

const FaxToModal = ({setShowFax}) => {
  return (
    <div>
        <div className="modals" id="fax-myemail">
  <div className="modal-dialog modal-xl">
    <div className="modal-content">

      {/* <!-- Modal Header --> */}
      <div className="modal-header text-center d-block border-0">
        <h4 className="modal-title text-uppercase">Fax2MyEmail<sup>TM</sup></h4>
        <div className="small-devider mb-2"></div>
        <p><strong>Description </strong>   Fax: 1 (818) 646-8070 alan's Phone </p>
        <div className="fax-to-mail-status">
          <label><input type="radio" name="fax-status"/> Enable</label>
          <label><input type="radio" name="fax-status"/> Disabled</label>
        </div>
        <button type="button" className="close" onClick={()=>setShowFax(false)}>&times;</button>
      </div>

      {/* <!-- Modal body --> */}
      <div className="modal-body bg-white">
        <div className="row ml-0 mr-0">
          <div className="col-12 col-lg-6">
            <div className="fax-mail-panel shadow-sm">
              <div className="title-header">Email New Message Notifications To <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span></div>
              <div className="content-body">
                <label>Email Address</label>
                <input type="text" className="form-control" placeholder="info@forwarding.com"/>
                <button type="button" className="btn btn-labeled btn-number pt-0 pb-0 mr-3 text-uppercase mt-4">
                  <span className="btn-label"><img src={add} alt=""/></span>Add More Email Addresses
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="fax-mail-panel shadow-sm">
              <div className="title-header">Fax Format</div>
              <div className="content-body">
                <div className="select-fax-formt">
                  <label className="mr-4"><input type="radio" name="fax-frmt" id=""/> Use PDF format for fax  </label>
                  <label><input type="radio" name="fax-frmt" id=""/> Use TIFF format for fax   </label>
                </div>
                <div className="fax-frmt-fields">
                  <label><input type="checkbox" name="encrypt-pdf" id=""/> Encrypt PDF <span className="text-success" data-toggle="tooltip" title="" data-original-title="help"><i className="fas fa-question-circle"></i></span></label>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="form-group mb-lg-0">
                        <input type="password" className="form-control" placeholder="Password"/>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="form-group mb-0">
                        <input type="password" className="form-control" placeholder="Verify Password"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal footer --> */}
      <div className="modal-footer justify-content-center border-0">
        <button type="submit" className="btn btn-orange px-4">Save</button>
        <button type="button" className="btn btn-dark px-4" onClick={()=>setShowFax(false)}>Cancel</button>
      </div>

    </div>
  </div>
</div>
    </div>
  )
}

export default FaxToModal