import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { bin, call, mailBox } from '../../assests'
import { MessageCenterTable } from '../../assests/mock_data/array'
import Layout from '../../components/Layout/Layout'
import CreateNewMil from './CreateNewMil'

const MessageCenter = () => {
    const[show,setShow]=useState(false)
  return (
    <div>
        <Layout>
        <div className="main-wrapper">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">
                    Message center
                </li>
              </ol>
            </nav>
          </div>

          <div className="col-sm-12">
            <h2 className="p-2 mb-3 text-uppercase">Message center</h2>
            
              {/* <!-- Tabs nav --> */}
              <div className="card shadow-sm">
                <div className="card-body py-5">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Mailbox</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="row">
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group">
                                                <select name="" id="" className="form-control">
                                                    <option value="">All</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <Link onClick={()=>setShow(!show)} href="#" className="btn btn-labeled btn-number pt-1 pb-1 mr-3 text-uppercase" data-toggle="modal" data-target="#voice2mymail">
                                                <img src={mailBox} alt="" className="mr-2"/> Create New Mailbox
                                              </Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="row ml-0 mr-0 align-items-center">
                        <div className="col1 ml-auto show-all-select">
                            <label>Show </label>
                                <select className="form-control">
                                    <option>All</option>
                                </select>
                            
                        </div>
                    </div>
                    <div className="msg-grid mt-3 table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th colspan="8">Message</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                MessageCenterTable?.map(({date,time,caller_Id,number,type,note,download})=>{
                                    return(
                                        <tr>
                                    <td>
                                       <input type="checkbox" name="" id=""/>
                                    </td>
                                    <td>{date}</td>
                                    <td>{time}</td>
                                    <td> {caller_Id}</td>
                                    <td>{number}</td>
                                    <td>{type}</td>
                                    <td>{note}</td>
                                    <td>{download}</td>
                                </tr>

                                    )
                                })
                            }
                                
                            </tbody>
                        </table>
                    </div>  

                    <div className="row ml-0 mr-0 align-items-center justify-content-between">
                        <div className="col1"><Link href="#" className="btn del-call-btn"><img src={bin} alt=""/> Delete Selected Messages</Link></div>
                        <div className="col1 ml-auto show-all-select">
                            <label>Show </label>
                                <select className="form-control">
                                    <option>All</option>
                                </select>
                            
                        </div>
                    </div>
                    
                </div>
                
              </div>
            

            {/* <!-- Tabs content --> */}
          </div>
        </div>
      </div>
     
    </div>
    {show && 
    <CreateNewMil setShow={setShow}/>}
        </Layout>
    </div>
  )
}

export default MessageCenter