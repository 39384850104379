import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { manageUserActive, manageUserInactive } from '../../assests/mock_data/array'
import Layout from '../../components/Layout/Layout'
import HistoryModal from './HistoryModal'

const ManageUsers = () => {
    const array=["Active","Inactive"]
    const[state,setState]=useState("Active")
    const[historyModal,setHistoryModal]=useState(false)

  return (
    <div>
        <Layout>
        <div className="main-wrapper">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">
                  Manage Users
                </li>
              </ol>
            </nav>
          </div>

          <div className="col-sm-12">
            <h2 className="p-2 mb-3 text-uppercase">Manage Users</h2>
            
              {/* <!-- Tabs nav --> */}
              <div className="card shadow-sm">
                <div className="card-body py-5">
                    <ul className="nav nav-tabs">
                    {array.map((val)=>{
                        return(
                            <li className="active tab-li"><Link onClick={()=>setState(val)} className={val===state?"active show":" "} data-toggle="tab" href="#active">{val}</Link></li>
                        )
                    })}
                       
                        <li className="add-user-btn ml-auto"><Link to="/AddnewUser" href="add-user.html" className="btn btn-orange"><i className="fa fa-circle-plus mr-2"></i>Add New User</Link></li>
                      </ul>
                      
                      <div className="tab-content">
                        <div id="active" className={`tab-pane  shadow-none ${state==="Active"?"active":" "}`}>
                          <div className="table-responsive">
                            <table className="table">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Status</th>
                                    <th>Ext.</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>User Name</th>
                                    <th>Permissions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                manageUserActive.map(({Edit,History,Status,Ext,LastName,FirstName,Username,Permissions})=>{
                                    return(
                                        <tr>
                                    <td>
                                        <div className="action-btns">
                                            <Link href="#" className="mr-3"><i className="fa-solid fa-lock text-dark"></i></Link>
                                            <Link to="/editUser" href="edit-user.html" className="btn btn-labeled btn-number mr-3">
                                                <i className="fa-regular fa-pen-to-square"></i> {Edit}
                                            </Link>
                                            <Link onClick={()=>setHistoryModal(!historyModal)} href="#" className="btn btn-labeled btn-manage text-uppercase" data-toggle="modal" data-target="#user-history-modal">
                                                <span className="btn-label12"><i className="fa fa-repeat" aria-hidden="true"></i></span> {History}
                                            </Link>
                                        </div>
                                    </td>
                                    <td>{Status}</td>
                                    <td>{Ext}</td>
                                    <td>{LastName}</td>
                                    <td>{FirstName}</td>
                                    <td>{Username}</td>
                                    <td>{Permissions}</td>
                                </tr>

                                    )
                                })
                            }
                                
                            </tbody>

                            </table>
                          </div>
                        </div>
                        <div id="inactive" className={`tab-pane  shadow-none ${state==="Inactive"?"active":" "}`}>
                          <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Status</th>
                                        <th>Ext.</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>User Name</th>
                                        <th>Permissions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {manageUserInactive.map(({Status,Ext,LastName,FirstName,Username,Permissions})=>{
                                    return(
                                        <tr>
                                        <td>
                                            
                                        </td>
                                        <td>{Status}</td>
                                        <td>{Ext}</td>
                                        <td>{LastName}</td>
                                        <td>{FirstName}</td>
                                        <td>{Username}</td>
                                        <td>{Permissions}</td>
                                    </tr>

                                    )
                                })}
                                    
                                </tbody>
    
                            </table>
                          </div>  
                        </div>
                        
                      </div>
                    
                </div>
                
              </div>
            

            {/* <!-- Tabs content --> */}
          </div>
        </div>
      </div>
     
    </div>
    {
        historyModal && 
        <HistoryModal setHistoryModal={setHistoryModal}/>
    }
        </Layout>
    </div>
  )
}

export default ManageUsers