import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import { AccountBilling, AccountContacts, AccountDetail ,AccountSetting,QuickLinks,AccountInfom, EndUser} from '../../assests/mock_data/array'
import { Enduser, QuickLink, save, unsub, WhiteRefresh } from '../../assests'

const AccountInfo = () => {
  return (
    <div>
        <Layout>
        <div className="main-wrapper">
      <div className="container-fluid px-12 py-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">
                  Account Information
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-12">
            <h2 className="p-2 mb-3">
                Account Information 
            </h2>
          </div>
          
          <div className="col-lg-9">
            {/* <!-- Account Details --> */}
            <div className="card acc-info-card mb-5">
                <div className="card-body shadow-sm">
                    <h4>Account Details</h4>
                    <div className="small-devider"></div>
                    <div className="row">
                    {
                        AccountDetail.map(({img,head,text,refresh},index)=>{
                           
                            return(
                                <div className="col-12 col-lg-6 mb-4">
                            <div className="row">
                                <div className="col-10">
                                    <div className="card acct-details-card">
                                        <div className="card-body">
                                            <div className="acc_info_cont">
                                                <div className="_icn"><img src={img} alt="" /></div>
                                                <div className="_info">
                                                    <h4>{head}</h4>
                                                    {index === 7?  <p><strong class="text-danger">On Hold</strong></p>:<p>{text}</p>}
                                                    {index === 4 ? 
                                                        <div class="call-in-pin">
                                                        <a href="#"> [SHOW CALL-IN PIN]</a>
                                                    </div>:null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    [refresh] &&
                                    <div className="col-2">
                                    <div className="_refresh_icn"><Link href="#"><img src={refresh} alt="" /></Link></div>
                                </div>
                                }
                               
                            </div>
                        </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            {/* <!-- Account Settings --> */}
            <div className="card acc-info-card mb-5">
                <div className="card-body shadow-sm">
                    <div className="title-head">
                        <h4>Account Settings</h4>
                        <a href="#" className="btn btn-labeled btn-number pt-2 pb-2 mr-3 ">
                            <img src={WhiteRefresh} alt="" className="mr-2"/> Update account settings
                          </a>
                    </div>
                    <div className="small-devider"></div>
                    <div className="row">
                    {AccountSetting.map(({img,head,text})=>{
                        return(
                            <div className="col-12 col-lg-6 mb-4">
                            <div className="row">
                                <div className="col-10">
                                    <div className="card acct-details-card">
                                        <div className="card-body">
                                            <div className="acc_info_cont">
                                                <div className="_icn"><img src={img} alt="" /></div>
                                                <div className="_info">
                                                    <h4>{head}</h4>
                                                    <p>{text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        )
                    })}
                        
                        
                        
                        
                    </div>
                </div>
            </div>
            {/* <!-- Account Contacts --> */}
            <div className="card acc-info-card mb-5">
                <div className="card-body shadow-sm">
                    <div className="title-head">
                        <h4>Account Contacts</h4>
                        <Link href="#" className="btn btn-labeled btn-number pt-2 pb-2 mr-3 ">
                            <img src={WhiteRefresh} alt="" className="mr-2"/> Update account contacts
                          </Link>
                    </div>
                    <div className="small-devider"></div>
                    <div className="row">
                    {
                        AccountContacts.map(({img,head,text},index)=>{
                            return(
                                <div className="col-12 col-lg-6 mb-4">
                            <div className="row">
                                <div className="col-10">
                                    <div className="card acct-details-card">
                                        <div className="card-body">
                                            <div className="acc_info_cont">
                                                <div className="_icn"><img src={img} alt="" /></div>
                                                <div className="_info">
                                                {index===1?<> <h4>{head}</h4>
                                                    <p className="mb-0">{text}</p>
                                                     <small className="text-muted">United States - Burbank - Burbank Da, CA</small></>
                                                     :
                                                     <><h4>{head}</h4>
                                                    <p>{text}</p></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                            )
                        })
                    }
                        
                       
                       
                       
                        
                        
                    </div>
                </div>
            </div>
            {/* <!-- My contact information --> */}
            <div className="card acc-info-card mb-5">
                <div className="card-body shadow-sm">
                    <div className="title-head">
                        <h4>My contact information</h4>
                        <Link href="#" className="btn btn-labeled btn-number pt-2 pb-2 mr-3 ">
                            <img src={WhiteRefresh} alt="" className="mr-2"/> Update my contact information
                          </Link>
                    </div>
                    <div className="small-devider"></div>
                    <div className="row">
                    {AccountInfom.map(({img,head,text})=>{
                        return(
                            <div className="col-12 col-lg-6 mb-4">
                            <div className="row">
                                <div className="col-10">
                                    <div className="card acct-details-card">
                                        <div className="card-body">
                                            <div className="acc_info_cont">
                                                <div className="_icn"><img src={img} alt="" /></div>
                                                <div className="_info">
                                                    <h4>{head}</h4>
                                                    <p>{text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>


                        )
                    })}
                       
                        
                        
                        
                    </div>
                </div>
            </div>

            {/* <!-- Billing Address --> */}
            <div className="card acc-info-card mb-5">
                <div className="card-body shadow-sm">
                    <div className="title-head">
                        <h4>Billing Address</h4>
                        <Link href="#" className="btn btn-labeled btn-number pt-2 pb-2 mr-3 ">
                            <img src={WhiteRefresh} alt="" className="mr-2"/> Update billing address
                          </Link>
                    </div>
                    <div className="small-devider"></div>
                    <div className="row">
                    {AccountBilling.map(({img,head,text})=>{
                        return(
                            <div className="col-12 col-lg-6 mb-4">
                            <div className="row">
                                <div className="col-10">
                                    <div className="card acct-details-card">
                                        <div className="card-body">
                                            <div className="acc_info_cont">
                                                <div className="_icn"><img src={img} alt="" /></div>
                                                <div className="_info">
                                                    <h4>{head}</h4>
                                                    <p>{text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        )
                    })}
                        
                        
                      
                    
                        
                        
                    </div>
                </div>
            </div>

            {/* <!-- End User Address --> */}
            <div class="card acc-info-card mb-5">
                <div class="card-body shadow-sm">
                    <div class="title-head">
                        <h4>End User Addresses [0]</h4>
                        <a href="#" class="btn btn-labeled btn-number pt-2 pb-2 mr-3 ">
                            <img src={Enduser} alt="" class="mr-2"/> Manage End User Addresses
                          </a>
                    </div>
                    <div class="small-devider"></div>
                    <div class="sub-title">
                        <h5>Opt-In Choices:</h5>
                        <p>Check or uncheck the applicable box(es):</p>
                    </div>
                    <div class="row">
                    {EndUser.map(({img,head})=>{
                        return(
                            <div className="col-12 col-lg-6 mb-4">
                            <div className="row">
                                <div className="col-10">
                                    <div className="card acct-details-card">
                                        <div className="card-body">
                                            <div className="acc_info_cont">
                                                <div className="_icn"><img src={img} alt="" /></div>
                                                <div className="_info">
                                                    <h4>{head}</h4>
                                                    <div className="choices">
                                                        <label><input type="radio" name="newsletter" id=""/> Send</label>
                                                        <label><input type="radio" name="newsletter" id=""/> Don't Send</label>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        )
                    })}
                       
                      
                        <div className="col-12 col-lg-12">
                            <div className="action-btns mt-3 mb-3">
                                <Link href="#" className="btn btn-labeled btn-manage pt-0 pb-0 mr-3 text-uppercase">
                                    <span className="btn-label"><img src={unsub} alt=""/></span>Unsubscribe From All
                                </Link>
                                <Link href="#" className="btn btn-labeled btn-number pt-0 pb-0 mr-3 text-uppercase">
                                    <span className="btn-label"><img src={save} alt=""/></span>Save These Choices
                              </Link>
                            </div>
                        </div>
                    
                        
                        
                    </div>
                </div>
            </div>
            {/* <!-- Important Note --> */}
           <div className="imp-note">
            <p><span className="text-danger">*</span> You have at least one active account and must be able to receive important announcements about your service. </p>
           </div>
          </div>

          <div class="col-lg-3">
            <div class="right-sidebar bg-white">
              <div class="d-flex">
                <h5 class="pl-3 pr-3 pt-2 pb-2 w-100 rounded">
                  <img src={QuickLink} alt="" class="w-10 mr-3" />Quick Links
                </h5>
              </div>
              <div class="sidebar-content pt-2 pl-3 pr-3 pb-3">
                <div class="advanced-feats">
                    <div class="adv-feat-lists">
                    {QuickLinks.map(({img,text})=>{
                        return(
                            <div class="feats-box">
                            <Link href="#">
                                <span class="icn-cont"><img src={img} alt="" /></span>
                                <span class="feat-txt">{text}</span>
                            </Link>
                        </div>

                        )
                    })}
                        
                       
                        
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </Layout>
    </div>
  )
}

export default AccountInfo