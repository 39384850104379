import {React,useState} from "react";
import { Link } from "react-router-dom";
import { add, Addcall, call, check, editCall, exportt, extension, mail, phoneNum, record, searchMail, setting, video, wh } from "../../assests";
import { CallExtension } from "../../assests/mock_data/array";
import Layout from "../../components/Layout/Layout";
import EditActionModal from "./EditActionModal";
import FaxToModal from "./FaxToModal";
import NewCall from "./NewCall";


const ManagePhoneNum = () => {
    const [phNum,setPhNum]=useState(true)
  const [callAction,setCallAction]=useState(false)
  const [seting,setSettings]=useState(false)
  const [editModal,setEditModal]=useState(false)
  const [createNew,setCreateNew]=useState(false)
  const [showFax,setShowFax]=useState(false)
  const PhoneNumber=()=>{
    setPhNum(true)
    setCallAction(false)
    setSettings(false)
  }
  const CallAction=()=>{
    setCallAction(true)
    setPhNum(false)
    setSettings(false)
  }
  const Setings=()=>{
    setSettings(true)
    setPhNum(false)
    setCallAction(false)
  }
  console.log("editmodall",editModal);

  return (
    <Layout>
      <div className="main-wrapper">
        <div className="container-fluid px-12 py-4">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-transparent">
                  <li className="breadcrumb-item">
                    <Link href="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Phone Numbers
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-sm-12">
              <h2 className="p-2 mb-3">Phone numbers </h2>
              <div className="d-md-flex active-white">
                {/* <!-- Tabs nav --> */}
                <div
                  className="nav nav-pills nav-pills-custom"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <Link
                    className={`nav-link mb-3 pt-4 mr-3 pb-4 shadow ${phNum? "active":" "}`}
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={PhoneNumber}
                  >
                    <img src={phoneNum} alt="" className="mt-2" />

                    <span className="font-weight-bold small text-uppercase mt-2">
                      Phone Number
                    </span>
                  </Link>

                  <Link
                    className={`nav-link mb-3 pt-4 pb-4 mr-3 shadow ${callAction? "active":""}`}
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                    onClick={CallAction}
                  >
                    <img src={extension} alt="" />
                    <span className="font-weight-bold small text-uppercase mt-2">
                      Call Actions/ <br />
                      Extensions
                    </span>
                  </Link>

                  <Link
                    className={`nav-link mb-3 pt-4 pb-4 shadow ${seting? "active":""}`}
                    id="v-pills-setting-tab"
                    data-toggle="pill"
                    href="#v-pills-setting"
                    role="tab"
                    aria-controls="v-pills-setting"
                    aria-selected="false"
                    onClick={Setings}
                  >
                    <img src={setting} alt="" className="mt-2" />
                    <span className="font-weight-bold small text-uppercase mt-2">
                      Settings
                    </span>
                  </Link>
                </div>
              </div>

              {/* <!-- Tabs content --> */}
              <div className="tab-content" id="v-pills-tabContent">
              {

                phNum &&
              
                <div
                  className="tab-pane fade shadow rounded show active p-2"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <div className="row justify-content-between px-3">
                    <div>
                      <button
                        type="button"
                        className="btn btn-labeled btn-number pt-0 pb-0 mr-3 text-uppercase"
                      >
                        <span className="btn-label">
                          <img src={call} alt="" />
                        </span>
                        Get a New phone number
                      </button>
                    </div>

                    <div className="d-flex justify-content-between mt-2 px-3 ph-no-tab-actions">
                      <span>
                        <img src={video} alt="" /> View Tutorial
                      </span>
                      <span className="mx-3">
                        <img src={record} alt="" /> Call Recording
                      </span>

                      <span className="">
                        <img src={exportt} alt="" /> Export My Phone
                        Numbers
                      </span>
                    </div>
                  </div>
                  <div className="table-responsive mob-table">
                    <table className="table table-bordered mt-3">
                      <tbody>
                        <tr>
                          <th style={{width:"40%"}}>
                            Phone Number
                            <i className="fa fa-sort-desc" aria-hidden="true"></i>
                            <span
                              className="text-success"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="help"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </th>
                          <th style={{width:"30%"}}>
                            Fax2MyEmail <sup>TM</sup>
                            <span
                              className="text-success"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="help"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </th>
                          <th>Call Action</th>
                        </tr>
                        <tr>
                          <td>
                            <div className="mob-heading">
                              Phone Number{" "}
                              <span
                                className="text-success"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="help"
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </div>
                            <div className="mb-3 mt-3">
                              Name
                              <span className="accountent-name rounded pl-3 pr-3 pt-2 pb-2">
                                alan’s Phone - 1 ( 818 ) 646-8070
                              </span>
                            </div>
                            <h5 style={{fontSize: "14px"}}>
                              Van Nuys <b> (818)646-8070</b>
                            </h5>
                            <p style={{fontSize: "12px" ,color: "red"}}>
                              Suspended: this number is temporarily out of
                              service; please check your account balance or your
                              email for account status updates.
                            </p>
                            <div className="row small">
                              <div className="col-md-6 border-right">
                                <p className="mb-1">
                                  Ringback Tone: <Link href="#"> United States</Link>
                                </p>

                                <p>
                                  Call Recording: <Link href="#">Disabled</Link>
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p className="mb-1">
                                  Ringback Tone: <Link href="#"> United States</Link>
                                </p>

                                <p>
                                  Address:
                                  <Link href="Manage-user-address.html">
                                    Manage Adddress
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="mob-heading">
                              Fax2MyEmail <sup>TM</sup>
                              <span
                                className="text-success"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="help"
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </div>
                            <p className="mb-1">
                              Format: <b className="text-dark">PDF</b>
                            </p>
                            <p>Email: Infoecommercepak.com</p>

                            <button
                              type="button"
                              className="btn borderg bg-white w-75 btn-labeled btn-light border text-dark pt-0 pb-0 mr-3 text-uppercase"
                              data-toggle="modal"
                              data-target="#fax-myemail"
                              onClick={()=>setShowFax(!showFax)}
                            >
                              <span className="btn-label bg-white pr-0 text-left">
                                <img src={mail} alt="" />
                              </span>
                              Change Settings
                            </button>
                          </td>
                          <td>
                            <div className="mob-heading">Call Action</div>
                            <select
                              className="custom-select"
                              id="inputGroupSelect03"
                            >
                              <option selected>101 - Call alan</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>

                            <p className="px-2 pt-3">
                              <img src={searchMail} alt="" x />
                              <Link href="#">View Message</Link>
                            </p>
                            <button
                              type="button"
                              className="btn btn-labeled btn-light border bg-light border-secondary text-dark py-1 px-3 mr-3 text-uppercase"
                              data-toggle="modal"
                              data-target="#edit-action"
                              onClick={()=>setEditModal(!editModal)}
                            >
                              <img
                                src={editCall}
                                alt=""
                                className="pr-2"
                              />
                              Edit
                            </button>

                            <button
                              type="button"
                              className="btn btn-labeled btn-light border border-secondary text-dark py-1 px-3 mr-3 text-uppercase"
                              data-toggle="modal"
                              data-target="#new-callAction"
                              onClick={()=>setCreateNew(!createNew)}
                            >
                              <img
                                src={Addcall}
                                alt=""
                                className="pr-2"
                              />
                              New
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                }
                {
                    callAction &&
                

                <div
                  className="tab-pane fade shadow rounded show active p-2"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <div className="row justify-content-between px-3">
                    <div>
                      <button
                        type="button"
                        className="btn btn-labeled btn-number pt-0 pb-0 mr-3 text-uppercase"
                      >
                        <span className="btn-label">
                          <img src={add} alt="" />
                        </span>
                        Create Call Action / Extension
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-3 exten-tb">
                      <tbody>
                        <tr>
                          <th>
                            Extension
                            <i
                              className="fa fa-sort-desc pl-2"
                              aria-hidden="true"
                            ></i>
                            <span
                              className="text-success"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="help"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </th>
                          <th>
                            Name
                            <i
                              className="fa fa-sort-desc pl-2"
                              aria-hidden="true"
                            ></i>
                            <span
                              className="text-success"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="help"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </th>
                          <th>Type of call Action </th>
                          <th>
                            Filter <input type="text" />
                          </th>
                          <th>Delete</th>
                        </tr>
                        {CallExtension.map(({extension,name,type,typeImg,btn,filter,filterImg})=>{
                            return(
                                <tr>
                          <td>
                            <span className="w-100 border rounded p-2 d-block ">
                              {extension}
                            </span>
                          </td>
                          <td>
                            <span className="w-100 border rounded p-2 d-block">
                             {name}
                            </span>
                          </td>

                          <td>
                            <div className="d-flex justify-content-lg-around">
                              <p>{type}</p>
                              <button
                                type="button"
                                className="btn btn-labeled btn-light border border-secondary text-white p-1 w-50 border-0 "
                                style={{backgroundColor: "#5699D7"}}
                              >
                                <img src={typeImg} alt="" className="pr-2" />
                                {btn}
                              </button>
                            </div>
                          </td>
                          <td>
                            <p className="px-2 pt-3">
                              <img src={filterImg} alt="" />
                              <a href="#">{filter}</a>
                            </p>
                          </td>

                          <td className="text-center">
                            <i
                              className="fa fa-trash text-secondary"
                              aria-hidden="true"
                            ></i>
                          </td>
                        </tr>

                            )
                        })}
                        
                       
                      </tbody>
                    </table>
                  </div>
                </div>
                }
                {
                    seting &&
                
                <div
                  className="tab-pane fade shadow rounded show active p-2"
                  id="v-pills-setting"
                  role="tabpanel"
                  aria-labelledby="v-pills-setting-tab"
                >
                  <div>
                    <button
                      type="button"
                      className="btn btn-labeled btn-number pt-0 pb-0 mr-3 text-uppercase"
                    >
                      <span className="btn-label">
                        <img src={check} alt="" />
                      </span>
                      Save
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-3 exten-tb">
                      <tbody>
                        <tr>
                          <th className="w-50">Option </th>

                          <th className="w-50">Settings </th>
                        </tr>

                        <tr>
                          <td>
                            <p>
                              New Phone Number Call Action
                              <span
                                className="text-success"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="help"
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </p>
                          </td>

                          <td className="text-center">
                            <select
                              className="custom-select w-100"
                              id="inputGroupSelect03"
                            >
                              <option selected>Fax only</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <p>
                              New Phone Number Fax Status
                              <span
                                className="text-success"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="help"
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </p>
                          </td>

                          <td>
                            <select
                              className="custom-select w-25"
                              id="inputGroupSelect03"
                            >
                              <option selected>Yes</option>
                              <option value="1">No</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      { editModal && 
      
      <EditActionModal 
      setEditModal={setEditModal}
      /> 
     }
      {showFax && <FaxToModal
      setShowFax={setShowFax}
      />}
      {createNew && <NewCall
      setCreateNew={setCreateNew}
      /> }

     
    </Layout>
  );
};

export default ManagePhoneNum;
