import React, { useState } from "react";
import { Link } from "react-router-dom";
import { checkIcn, playIcn, heroRect1 } from "../../assests";
import Navbar from "../../components/organisms/Navbar/Navbar";
import Footer from "../../components/organisms/Footer/Footer";
import {
  AdvanceFeatures,
  SliderArray,
  TestiText,
} from "../../assests/mock_data/array";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BannerData, howItWork } from "./mock";

function HomePage() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const sliderSettings = {
    slidesToShow: 5,
    dots: false,
    className: "center",
    swipeToSlide: true,
    focusOnSelect: true,
    centerMode: true,
     centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const SliderFor = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  };

  return (
    <div>
      <Navbar />
      {/* <!--End Header -->
<!-- Start Banner Section --> */}
      <section className="banner-section">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-12 col-md-7">
              <div className="hero-dev-line"></div>
              <h1 className="text-uppercase">
                Get A Smart International Phone <span>Number</span>
              </h1>
              <div className="features-list">
                <ul>
                  {BannerData.map(({ text }, index) => {
                    return (
                      <li key={index}>
                        <span className="check-icn">
                          <img src={checkIcn} alt="check-icn" />
                        </span>
                        {text}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="hero-btns mt-5">
                <Link href="#" className="btn btn-orange text-uppercase">
                  Try for Free
                </Link>
                <Link href="#" className="play-btn">
                  <img src={playIcn} alt="play-icn" />
                </Link>
              </div>
            </div>

            {/* Modify in future Start */}
            <div className="col-12 col-md-5">
              <div className="hero-form-cont">
                <div className="form-group cst-slelct">
                  <select className="form-control ">
                    <option>United Kingdom</option>
                    <option>USA</option>
                  </select>
                </div>
                <div className="form-group cst-slelct">
                  <select className="form-control">
                    <option>USA/Canada Toll Free (True 800)</option>
                    <option>USA</option>
                  </select>
                </div>
                <div className="form-group cst-slelct">
                  <select className="form-control">
                    <option>1 (800) 285-3619</option>
                    <option>USA</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-orange btn-block text-uppercase"
                    value="Submit"
                  />
                </div>
                <div className="form-group mb-0 d-flex justify-content-between">
                  <span className="abt-toll-free-caption text-white">
                    About UK Toll Free 800
                  </span>
                  <Link to="#" className="skip-trial">
                    Skip the Trial - Buy Now
                  </Link>
                </div>
              </div>
            </div>

            {/* Modify in future End*/}
          </div>
          <div className="hero-deco">
            <img src={heroRect1} alt="" />
          </div>
        </div>
      </section>

      {/* <!-- End Banner Section --> */}
      {/* <!-- Start Global Section --> */}
      <section className="global-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="global-content">
                <h2 className="section-title text-uppercase">
                  Go Global in 60 Seconds
                </h2>
                <div className="small-devider"></div>
                <p className="mt-5 mb-5">
                  Activate your new international phone number in 60 seconds.
                  Choose from phone numbers in over 120+ countries and 1,000
                  cities. Forward your calls to any phone number in the world.
                </p>
                <Link href="#" className="btn btn-orange text-uppercase">
                  Watch Video
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="vid-cont">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/ANs_jr3hh84"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Global Section --> */}
      {/* <!-- Start How it Works --> */}
      <section className="how-it-works">
        <div className="container">
          <div className="section-title-cont text-center">
            <h2 className="section-title text-uppercase">
              How It works - 3 simple steps
            </h2>
            <div className="small-devider"></div>
          </div>
          <div className="steps3-cont">
            <div className="row">
              {howItWork.map(({ text, title }, index) => {
                return (
                  <div className="col-12 col-md-4" key={index}>
                    <div
                      className={`step-div text-center ${
                        index === 1 ? "step2" : ""
                      }`}
                    >
                      <div className="step-counter">
                        <span className="step-no">{index + 1}</span>
                      </div>
                      <div className="step-content">
                        <h3 className="text-uppercase">{title}</h3>
                        <p>{text}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-center mt-5">
            <Link href="#" className="btn btn-orange">
              GET YOUR PHONE NUMBER NOW
            </Link>
          </div>
        </div>
      </section>
      {/* <!-- End  How it Works --> */}

      {/* <!-- Start Trust Pilot Section --> */}
      <section className="trust-pilot-cont">
        <div className="container">
          <div className="section-title-cont text-center text-uppercase">
            <h2>
              See why over 20,000 customers trust TollFreeForwarding for their
              virtual phone number needs
            </h2>
            <div className="small-devider"></div>
          </div>
          <div className="trust-reviews-cont">
            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="54ad5defc6454f065c28af8b"
              data-businessunit-id="554337b50000ff00057f1ff0"
              data-style-height="200px"
              data-style-width="100%"
              data-theme="light"
              data-stars="4,5"
              data-review-languages="en"
              style={{ position: "relative" }}
            >
              <iframe
                style={{
                  position: " relative",
                  height: "200px",
                  width: "100%",
                  borderStyle: "none",
                  display: "block",
                  overflow: "hidden",
                }}
                title="Customer reviews powered by Trustpilot"
                src="https://widget.trustpilot.com/trustboxes/54ad5defc6454f065c28af8b/index.html?templateId=54ad5defc6454f065c28af8b&amp;businessunitId=554337b50000ff00057f1ff0#locale=en-US&amp;styleHeight=200px&amp;styleWidth=100%25&amp;theme=light&amp;stars=4%2C5&amp;reviewLanguages=en"
                data-gtm-yt-inspected-6="true"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Trust Pilot Section --> */}

      {/* <!-- Start Advanced Feat --> */}
      <section className="advanced-feat-cont">
        <div className="container-fluid px-10">
          <div className="section-title-cont text-center text-uppercase">
            <h2>Advanced Features</h2>
            <div className="small-devider"></div>
          </div>
          <div className="adv-feat-boxes mt-5">
            <div className="row">
              {AdvanceFeatures.map(({img,head,body},index) => {
                return (
                  <div className="col-12 col-md-4">
                    <div
                      className={
                        index === 1 ? "feat-box feat-middle" : "feat-box"
                      }
                    >
                      <div className="feat-content">
                        <div className="cal-icn">
                          <img src={img} alt="" />
                        </div>
                        <div className="feat-text">
                          <h3 className="text-uppercase">{head}</h3>
                          <p>{body}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="feat-sec-btn text-center">
              <Link to="#" className="feat-btn">
                GET YOUR PHONE NUMBER NOW
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Advanced Feat --> */}

      {/* <!-- Start Map Section --> */}
      <section className="map-section">
        <div className="container">
          <div className="sectiont-title text-center">
            <h2 className="text-uppercase">
              Join over 20,000 customers already using TollFreeForwarding
            </h2>
            <div className="small-devider"></div>
            <h4>
              Setup in just one minute. No credit card required. Cancel any
              time.
            </h4>
          </div>
          <div className="text-center mt-5">
            <Link href="#" className="btn btn-orange">
              GET YOUR PHONE NUMBER NOW
            </Link>
          </div>
        </div>
      </section>
      {/* <!-- End Map Section --> */}

      <section className="testi-section">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="text-uppercase">Clients</h2>
            <div className="small-devider"></div>
          </div>
          <div className="test-slider-cont">
            <div className="slider-nav">
              <Slider
                {...sliderSettings}
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
              >
                {SliderArray.map((val) => {
                  return (
                    <div className="slider-nav-item">
                      <img src={val.img} alt="" />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="slider-for mt-5">
              <Slider
                {...SliderFor}
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
              >
                {TestiText.map((val) => {
                  return (
                    <div className="testi-txt-cont">
                      <p>
                        <span className="quote-icn">
                          <img src={val.imgOpen} alt="" />
                        </span>
                        {val.text}
                        <span className="quote-icn">
                          <img src={val.imgClose} alt="" />
                        </span>
                      </p>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Start Footer Section --> */}
      <Footer />
      {/* <!-- End Footer Section --> */}
    </div>
  );
}

export default HomePage;
