import{ React,useState} from 'react'
import { Link } from 'react-router-dom'
import { spam, upload } from '../../assests'
import Layout from '../../components/Layout/Layout'
import PaymentModal from './PaymentModal'

const CallHistory = () => {
    const [showPayModal,setshowPayModal]=useState(false)
  return (
    <div>
        <Layout>
        <div className="main-wrapper">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">
                  Call History
                </li>
              </ol>
            </nav>
          </div>

          <div className="col-sm-12">
            <h2 className="p-2 mb-3 text-uppercase">Call History</h2>
            
              {/* <!-- Tabs nav --> */}
              <div className="card shadow-sm">
                <div className="card-body py-5">
                    <div className="row ml-0 mr-0">
                        <div className="col-12 col-lg-12">
                        <div className="fax-mail-panel billing-panel shadow-sm">
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="form-group">
                                            <label for="">Start Date:</label>
                                            <input type="date" name="date" className="form-control" id=""/>
                                        </div>
                                        <div className="form-group">
                                            <label for="">End Date:</label>
                                            <input type="date" name="date" className="form-control" id=""/>
                                        </div>
                                        <label for="">Show Calls:</label>
                                        <div className="row">
                                            <div className="col-12 col-lg-5">
                                                <div className="form-group">
                                                    <select className="form-control">
                                                        <option>Completed</option>
                                                        <option>Completed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="query-btn">
                                                <Link href="#" className="btn btn-light">Query</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="form-group">
                                            <label for="">Virtual Number:</label>
                                            <select name="" id="" className="form-control">
                                                <option value="">All Phone Numbers</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="">Time Zone:</label>
                                            <select name="" id="" className="form-control">
                                                <option value="">(GMT-8) Pacific Time (US & Canda)</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="">Recorded Calls:</label>
                                            <select name="" id="" className="form-control">
                                                <option value="">Display both recorded and non-recorded calls</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-12 text-center">
                                        <div className="btns-cont text-center mb-4 mt-4">
                                            <Link href="#" className="btn btn-labeled btn-number mr-3">
                                                <img src={upload} alt="" className="mr-2"/> Export These Results
                                            </Link>
                                            <Link href="#" className="btn btn-labeled btn-manage  mr-3 text-uppercase">
                                                <span className="btn-label12 mr-2"><img src={upload} alt=""/></span>My Export
                                            </Link>
                                            <Link onClick={()=>setshowPayModal(!showPayModal)} href="#" className="btn btn-secondary block-btn  text-uppercase" data-toggle="modal" data-target="#manage-block-no">
                                                <span className="btn-label12 mr-2"><img src={spam} alt="" /></span>Manage Blocked Numbers
                                            </Link>
                                        </div>
                                        <p>No calls between 13 oct 2022 and 13 oct 2022</p>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    
                </div>
                
              </div>
            

            {/* <!-- Tabs content --> */}
          </div>
        </div>
      </div>
      
    </div>
    {
        showPayModal &&
        <PaymentModal setshowPayModal={setshowPayModal}/>
    }
        </Layout>
    </div>
  )
}

export default CallHistory