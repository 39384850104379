import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import {MyAccount,HowToSetUp} from "../../assests/mock_data/array"
import { Link } from "react-router-dom";
import { phoneNum,call,call2,howTo,callRecord,callForward, card, time, video, voiceMail, voiceMenu,Announcement} from "../../assests";

function Dashboard() {
  const [account,setAccount]=useState(true)
  const [phone,setPhone]=useState(false)
  const myAccount=()=>{
    setAccount(true)
    setPhone(false)

  }
  const phoneNumber=()=>{
    setPhone(true)
    setAccount(false)
    console.log(phone)
    

  }
  // this file will be changed
  return (
      <Layout>
        <div className="main-wrapper">
          <div className="container-fluid px-12 py-4">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-transparent">
                    <li className="breadcrumb-item">
                      <Link href="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My Account
                    </li>
                  </ol>
                </nav>
              </div>

              <div className="col-lg-9">
                <h2 className="p-2 mb-3">
                  Alan, welcome to your Online Control Center
                </h2>
                <div className="d-md-flex active-white">
                  {/* <!-- Tabs nav --> */}
                  <div
                    className="nav nav-pills nav-pills-custom"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <Link
                      className={`nav-link mb-3 pt-4 mr-5 pb-4 shadow ${account? "active":""}`}
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-home"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                      onClick={myAccount}
                    >
                      <img
                        src={card}
                        className="account-img"
                        alt=""
                      />
                      <span className="font-weight-bold small text-uppercase">
                        My Account
                      </span>
                    </Link>

                    <Link
                     className={`nav-link mb-3 pt-4 mr-5 pb-4 shadow ${phone? "active":""}`}
                      id="v-pills-phone-tab"
                      data-toggle="pill"
                      href="#v-pills-phone"
                      role="tab"
                      aria-controls="v-pills-phone"
                      aria-selected="false"
                      onClick={phoneNumber}
                    >
                      <img
                        src={phoneNum}
                        alt=""
                        className="mt-2"
                      />

                      <span className="font-weight-bold small text-uppercase mt-2">
                        Phone Number
                      </span>
                    </Link>
                  </div>
                </div>

                {/* <!-- Tabs content --> */}
                <div className="tab-content bg-white" id="v-pills-tabContent">
                {account && 
                  <div
                    className="tab-pane fade shadow rounded bg-white show active p-5"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="my-account-data">
                    {MyAccount.map(({title1,text1,title2,text2},index)=>{
                      return(
                        index === 2 || index=== 3?
                        <ul>
                        <li>{title1}:</li>
                        <li>
                          <strong>
                            {text1}
                            <Link href="#">
                              <i className="fas fa-refresh"></i>
                            </Link>
                          </strong>
                        </li>
                        <li>{title2}</li>
                        <li>
                          <strong>{text2}</strong>
                        </li>
                      </ul>
                      : 
                      <ul>
                        <li>{title1}:</li>
                        <li>
                          <strong>{text1}</strong>
                        </li>
                        <li>{title2}:</li>
                        <li>
                          <strong>{text2}</strong>
                        </li>
                      </ul>
                      
                        

                      )
                    })}
                      
                     
                      
                    </div>

                    <Link
                     to="/getNewNumber"
                      className="btn btn-labeled btn-number pt-0 pb-0 mr-3 text-uppercase"
                    >
                      <span className="btn-label">
                        <img src={call} alt="" />
                      </span>
                      Get a New phone number
                    </Link>

                    <Link
                      to="/managephone"
                      className="btn btn-labeled btn-manage pt-0 pb-0 mr-3 text-uppercase"
                    >
                      <span className="btn-label">
                        <img src={call2} alt="" />
                      </span>
                      Get Manage phone numbers
                    </Link>
                  </div>
                  }

                  {phone && 
                  <div
                    className="tab-pane fade shadow rounded bg-white show active px-4 py-5"
                    id="v-pills-phone"
                    role="tabpanel"
                    aria-labelledby="v-pills-phone-tab"
                  >
                    <div className="table-responsive mb-3">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th width="50%">
                              Phone Number
                              <i
                                className="fa fa-sort-desc ml-3"
                                aria-hidden="true"
                              ></i>
                            </th>

                            <th style={{textAlign: "center"}}>
                              Fax2MyEmail <sup style={{fontWeight: "500"}}>TM</sup>
                            </th>
                            <th style={{width: "22%", textAlign: "center"}}>
                              Call Action
                            </th>
                          </tr>
                          <tr>
                            <td>
                              <h5 style={{fontSize: "14px"}}>
                                Van Nuys <b> (818)646-8070</b>
                              </h5>
                              <p className="red_p pr-3">
                                Suspended: this number is temporarily out of
                                service; please check your account balance or
                                your email for account status updates.
                              </p>
                            </td>
                            <td style={{textAlign: "center"}}>
                              <p className="py-4">On</p>
                            </td>
                            <td style={{textAlign: "center"}}>
                              <p class="py-4">Call Alan</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <button
                      type="button"
                      className="btn btn-labeled btn-number pt-0 pb-0 mr-3 text-uppercase"
                    >
                      <span className="btn-label">
                        <img src={call} alt="" />
                      </span>
                      Get a New phone number
                    </button>

                    <button
                      type="button"
                      className="btn btn-labeled btn-manage pt-0 pb-0 mr-3 text-uppercase"
                    >
                      <span className="btn-label">
                        <img src={call2} alt="" />
                      </span>
                      Get Manage phone numbers
                    </button>
                  </div>
                  
                  }
                </div>
              </div>

              <div className="col-lg-3">
                <div className="right-sidebar bg-white">
                  <div className="d-flex">
                    <h5 className="pl-3 pr-3 pt-2 pb-2 w-100 rounded">
                      <img
                        src={howTo}
                        alt=""
                        className="w-10 mr-3"
                      />
                      How do I Set up
                    </h5>
                  </div>
                  <div className="sidebar-content pt-2 pl-3 pr-3 pb-3">
                    <ul className="list-videos p-0">
                    {HowToSetUp.map(({icon,text,img})=>{
                      return(
                        <li>
                        <Link href="#pageSubmenu1">
                          <span>
                            <img src={icon} alt="" />
                          </span>
                          {text}
                          <span className="float-right">
                            <img src={img} alt="" />
                          </span>
                        </Link>
                      </li>

                      )
                    })}
                      
                    </ul>

                    <form action="" className="ask-text pl-2 pr-2">
                      <h2 className="" style={{fontSize: "17px"}}>
                        Ask us a question or tell us what you think.
                      </h2>

                      <textarea
                        name=""
                        id=""
                        rows="4"
                        cols="18"
                        className="p-3 form-control"
                      ></textarea>
                      <button
                        type="button"
                        className="btn btn-orange px-3 mt-2"
                      >
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>{" "}
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </Layout>
  );
}

export default Dashboard;
