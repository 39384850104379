import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../../assests";
import Navbar from "../organisms/Navbar/Navbar";
import Footersmall from "./Footersmall";
const Layout = ({ children }) => {
  return (
    <>
      <header className="main-header py-3 bg-white">
        <div className="container-fluid px-10">
          <div className="header-cont d-flex justify-content-between align-items-center position-relative">
            <div className="logo-cont">
              <a href="#">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="menu-toggle show-mob">
              <i className="fas fa-bars"></i>
            </div>
            <div className="nav-section hide-mob">
              <div className="top-nav-cont d-flex justify-content-end align-items-center text-uppercase">
                <div className="call-btn">
                  <a href="#" className="btn btn-orange">
                    <i className="fa fa-user mr-2"></i> Account
                  </a>
                </div>
                <div className="call-btn">
                  <a href="#" className="btn btn-dark text-white">
                    <i className="fa fa-envelope mr-2"></i> Message
                  </a>
                </div>
                <div className="devider"></div>
                <a href="#" className="out-number">
                  Log Out
                </a>
                <div className="devider"></div>

                <a href="#" className="out-number">
                  Message (0)
                </a>
                <div className="devider"></div>
                <a href="#" className="ph-number">
                  <i className="fa fa-phone mr-2"></i> Contact Us
                </a>
              </div>
              <div className="main-nav-cont">
                <ul className="nav-items d-flex">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>
                    <Link to="/getNewNumber">Get a New Phone</Link>
                  </li>
                  <li>
                    <Link to="/managephone">Manage Phone Numbers</Link>
                  </li>
                  <li>
                    <Link to="/messageCenter">Message Center</Link>
                  </li>
                  <li>
                    <Link to="/billing">Billing</Link>
                  </li>
                  <li>
                    <Link to="/accountInfo">Account Info</Link>
                  </li>
                  <li>
                    <Link to="/manageUser">Manage User</Link>
                  </li>
                  <li>
                    <Link to="/callHistory">Call History</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      {children}

      <Footersmall />
    </>
  );
};

export default Layout;
