import React, { useState } from 'react'
import { card, cardIcn, modSetting } from '../../assests'
import Layout from '../../components/Layout/Layout'
import { Link } from 'react-router-dom'
import PaymentMethodModal from './PaymentMethodModal'
import PaymentModifModal from './PaymentModifModal'

const Billing = () => {
    const [modifModal,setModifModal]=useState(false)
    const [paymentModal,setPaymentModal]=useState(false)
  return (
    <div>
        <Layout>
        <div className="main-wrapper">
      <div className="container-fluid px-12 py-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">
                  Billing
                </li>
              </ol>
            </nav>
          </div>

          <div className="col-sm-12">
            <h2 className="p-2 mb-3 text-uppercase">Billing</h2>
            
              {/* <!-- Tabs nav --> */}
              <div className="card shadow-sm">
                <div className="card-body py-5">
                    <div className="row ml-0 mr-0">
                        <div className="col-12 col-lg-6">
                        <div className="fax-mail-panel billing-panel shadow-sm mb-4">
                            <div className="title-header d-flex justify-content-between align-items-center">
                                <span>Payment Methods</span>
                                <button onClick={()=>setPaymentModal(!paymentModal)} type="button" className="btn btn-labeled btn-number pt-0 pb-0" data-toggle="modal" data-target="#add-payment">
                                    <img src={cardIcn} alt="" className="mr-1" /> Add Payment Method
                                </button>
                            </div>
                            <div className="content-body">
                            <p> You currently have no payment methods on file. </p>
                            
                            </div>
                        </div>
                        </div>
                        <div className="col-12 col-lg-6">
                        <div className="fax-mail-panel billing-panel shadow-sm">
                            <div className="title-header d-flex justify-content-between align-items-center">
                                <span>Payment Settings </span>
                                <button onClick={()=>setModifModal(!modifModal)} type="button" className="btn btn-labeled btn-number pt-0 pb-0" data-toggle="modal" data-target="#payment-modification">
                                    <img src={modSetting} alt="" className="mr-1" /> Modify Settings
                                </button>
                            </div>
                            <div className="content-body">
                                <p>Recharge Amount: <strong>$5.00 </strong></p>
                                <p>When balance below: <strong>$5.00 </strong></p>
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <a href="#" class="btn btn-orange">Pay Now</a>
                    </div>
                </div>
                
              </div>
            

            {/* <!-- Tabs content --> */}
          </div>
        </div>
      </div>
      
    </div>
    {
        paymentModal &&
        <PaymentMethodModal setPaymentModal={setPaymentModal}
        />
    }
    {
        modifModal &&
        <PaymentModifModal setModifModal={setModifModal}/>
    }
        </Layout>
    </div>
  )
}

export default Billing