import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import {newNumber,vanityNum,transferNo, noICN, noIcn, mailIcn, phnRcv} from "../../assests"
import {VirtualPhNo,UsaLocalNumbers,InternationalNumbers,Features} from "../../assests/mock_data/array"
const GetNewNumber = () => {
  const [newNum,setNewNum]=useState(true)
  const [transfer,setTransfer]=useState(false)
  const [vanity,setVanity]=useState(false)
  const NewNumber=()=>{
    setNewNum(true)
    setTransfer(false)
    setVanity(false)

  }
  const TransferNumber=()=>{
    setTransfer(true)
    setNewNum(false)
    setVanity(false)
    

  }
  const VanityNumber=()=>{
    setVanity(true)
    setNewNum(false)
    setTransfer(false)
    
    

  }
  return (
      <Layout>
        <div className="main-wrapper">
          <div className="container-fluid px-12 py-4">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-transparent">
                    <li className="breadcrumb-item">
                      <Link href="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Get a new phone number
                    </li>
                  </ol>
                </nav>
              </div>

              <div className="col-sm-12">
                <h2 className="p-2 mb-3">Get a new phone number</h2>
                <div className="d-md-flex active-white">
                  {/* <!-- Tabs nav --> */}
                  <div
                    className="nav nav-pills nav-pills-custom"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <Link
                      className={`nav-link mb-3 pt-4 mr-3 pb-4 shadow ${newNum? "active":" "}`}
                      id="v-pills-new-number-tab"
                      data-toggle="pill"
                      href="#v-pills-new-number"
                      role="tab"
                      aria-controls="v-pills-new-number"
                      aria-selected="true"
                      onClick={NewNumber}
                    >
                      <img
                        src={newNumber}
                        className="account-img"
                        alt=""
                      />
                      <span className="font-weight-bold small text-uppercase">
                        Pick a new number
                      </span>
                    </Link>

                    <Link
                      className={ `nav-link mb-3 pt-4 mr-3 pb-4 shadow ${transfer? "active":" "} `}
                      id="v-pills-phone-tab"
                      data-toggle="pill"
                      href="#v-pills-phone"
                      role="tab"
                      aria-controls="v-pills-phone"
                      aria-selected="false"
                      onClick={TransferNumber}
                    >
                      <img
                        src={transferNo}
                        alt=""
                        width="80"
                        className="mt-2"
                      />

                      <span className="font-weight-bold small text-uppercase mt-2">
                        Transfer Number
                      </span>
                    </Link>
                    <Link
                      className={`nav-link mb-3 pt-4 mr-3 pb-4 shadow ${vanity? "active":" "}`}
                      id="v-pills-vanity-tab"
                      data-toggle="pill"
                      href="#v-pills-vanity"
                      role="tab"
                      aria-controls="v-pills-vanity"
                      aria-selected="false"
                      onClick={VanityNumber}
                    >
                      <img
                        src={vanityNum}
                        alt=""
                        width="80"
                        className="mt-2"
                      />

                      <span className="font-weight-bold small text-uppercase mt-4">
                        Get a Vanity Number
                      </span>
                    </Link>
                  </div>
                </div>

                {/* <!-- Tabs content --> */}
                <div className="tab-content bg-white" id="v-pills-tabContent">
                {newNum &&
                  <div
                    className="tab-pane fade shadow rounded bg-white show active p-5"
                    id="v-pills-new-number"
                    role="tabpanel"
                    aria-labelledby="v-pills-new-number-tab"
                  >
                    <h3>Virtual Phone Numbers</h3>
                    <div className="row mt-4">
                      <div className="col-12 col-xl-9 mb-5">
                        <div className="card shadow-lg mb-4">
                          <div className="card-body">
                            <h5>
                              North America Toll Free Numbers (USA, Canada,
                              Puerto Rico)
                            </h5>
                            <div className="small-devider"></div>
                            <div className="phone-nos-list">
                            {VirtualPhNo.map(({text,btn},index)=>{
                              return(
                                <div className="phone_number">
                                <span className="_ph_txt">
                                 {text}
                                </span>
                                <Link href="#" className={((index+1) %2==0)?"btn btn-dark":"btn btn-orange"}>
                                 {btn}
                                  <i className="fa fa-arrow-right"></i>
                                </Link>
                              </div>

                              )
                            })}
                              
                              
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-4">
                          <div className="col-12 col-lg-6">
                            <div className="card shadow-sm">
                              <div className="card-body">
                                <div className="card-title">
                                  <h5>USA Local Phone Numbers</h5>
                                  <div className="small-devider"></div>
                                </div>
                                <div className="ph_nos_list_cont">
                                  <ul>
                                  {UsaLocalNumbers.map(({place,btn})=>{
                                    return(
                                      <li>
                                      <span className="loc-txt">
                                        {place}
                                      </span>
                                      <span className="view-btn-box">
                                        <Link
                                          href="#"
                                          className="btn btn-default btn-sm"
                                        >
                                         {btn}
                                        </Link>
                                      </span>
                                    </li>
                                    )
                                  })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="card shadow-sm">
                              <div className="card-body">
                                <div className="card-title">
                                  <h5>International Phone Numbers</h5>
                                  <div className="small-devider"></div>
                                </div>
                                <div className="ph_nos_list_cont">
                                  <ul>
                                  {InternationalNumbers.map(({place,btn})=>{
                                    return(
                                      <li>
                                      <span className="loc-txt">
                                        {place}
                                      </span>
                                      <span className="view-btn-box">
                                        <Link
                                          href="#"
                                          className="btn btn-default btn-sm"
                                        >
                                          {btn}
                                        </Link>
                                      </span>
                                    </li>
                                    )
                                  })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="right-sidebar bg-white">
                          <div className="d-flex">
                            <h5 className="pl-3 pr-3 pt-2 pb-2 w-100 rounded">
                              <img
                                src={noIcn}
                                alt=""
                                className="w-10 mr-3"
                              />
                              Choose Number type
                            </h5>
                          </div>
                          <div className="sidebar-content pt-2 pl-3 pr-3 pb-3">
                            <div className="phone-dir-selects">
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" /> USA /
                                  Canada Toll Free
                                </label>
                                <select className="form-control">
                                  <option>
                                    USA/Canada Toll Free ( True 800 )
                                  </option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" /> USA Local
                                </label>
                                <select className="form-control">
                                  <option>USA, Alabama, Birmingham</option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" />{" "}
                                  International
                                </label>
                                <select className="form-control">
                                  <option>Albania - Tirana</option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <Link
                                  href="#"
                                  className="btn btn-labeled btn-number pt-2 pb-2 mr-3 text-uppercase"
                                >
                                  <img
                                    src={noICN}
                                    alt=""
                                    className="mr-2"
                                  />{" "}
                                  View Available Numbers
                                </Link>
                              </div>
                              <div className="form-group">
                                <Link href="#">RATE & INFORMATION</Link>
                              </div>
                            </div>
                            <div className="advanced-feats mt-4 mb-4">
                              <h4>Advanced Features</h4>
                              <div className="adv-feat-lists">
                                <div className="feats-box">
                                  <span className="icn-cont">
                                    <i
                                      className="fa fa-list-ol"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="feat-txt">
                                    International Numbers
                                  </span>
                                </div>
                                {Features.map(({img,text})=>{
                                  return(
                                    <div className="feats-box">
                                  <span className="icn-cont">
                                    <img
                                      src={img}
                                      alt=""
                                    />
                                  </span>
                                  <span className="feat-txt">
                                    {text}
                                  </span>
                                </div>

                                  )
                                })}
                               
                               
                              </div>
                              <h5>
                                <Link href="#">
                                  More Features...
                                  <i
                                    className="fa fa-angle-double-right"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </h5>
                            </div>
                            <div className="_assist mb-4">
                              <h4>Need Assistance?</h4>
                              <div className="contact-btn">
                                <Link href="#">
                                  <span className="cnt_txt">
                                    <img src={mailIcn} alt="" />{" "}
                                    Contact Us!
                                  </span>
                                  <span className="ph-icn">
                                    <img src={phnRcv} alt="" />
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <form action="" className="ask-text pl-2 pr-2">
                              <h2 className="" style={{fontSize: "17px"}}>
                                Ask us a question or tell us what you think.
                              </h2>

                              <textarea
                                name=""
                                id=""
                                rows="4"
                                cols="18"
                                className="p-3 form-control"
                              ></textarea>

                              <div className="form-group mt-4">
                                <label>
                                  <strong>Email Address:</strong>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Info@forwarding.com"
                                />
                              </div>
                              <button
                                type="button"
                                className="btn btn-orange px-3 mt-2"
                              >
                                <i
                                  className="fa fa-paper-plane"
                                  aria-hidden="true"
                                ></i>{" "}
                                Send question / comment
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                    {transfer &&
                  <div
                    className="tab-pane fade shadow rounded bg-white show active px-4 p-5"
                    id="v-pills-phone"
                    role="tabpanel"
                    aria-labelledby="v-pills-phone-tab"
                  >
                    <h3>Transfer Phone Number</h3>
                    <div className="row mt-4">
                      <div className="col-12 col-xl-9 mb-5">
                        <div className="card shadow-lg mb-4">
                          <div className="card-body">
                            <h5>
                              North America Toll Free Numbers (USA, Canada,
                              Puerto Rico)
                            </h5>
                            <div className="small-devider"></div>
                            <div className="phone-nos-list">
                            {VirtualPhNo.map(({text,btn},index)=>{
                              return(
                                <div className="phone_number">
                                <span className="_ph_txt">
                                 {text}
                                </span>
                                <Link href="#" className={((index+1)%2==0)?"btn btn-dark":"btn btn-orange"}>
                                  {btn}
                                  <i className="fa fa-arrow-right"></i>
                                </Link>
                              </div>

                              )
                            })}
                              
                              
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-4">
                          <div className="col-12 col-lg-6">
                            <div className="card shadow-sm">
                              <div className="card-body">
                                <div className="card-title">
                                  <h5>USA Local Phone Numbers</h5>
                                  <div className="small-devider"></div>
                                </div>
                                <div className="ph_nos_list_cont">
                                <ul>
                                  {UsaLocalNumbers.map(({place,btn})=>{
                                    return(
                                      <li>
                                      <span className="loc-txt">
                                        {place}
                                      </span>
                                      <span className="view-btn-box">
                                        <Link
                                          href="#"
                                          className="btn btn-default btn-sm"
                                        >
                                         {btn}
                                        </Link>
                                      </span>
                                    </li>
                                    )
                                  })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="card shadow-sm">
                              <div className="card-body">
                                <div className="card-title">
                                  <h5>International Phone Numbers</h5>
                                  <div className="small-devider"></div>
                                </div>
                                <div className="ph_nos_list_cont">
                                <ul>
                                  {InternationalNumbers.map(({place,btn})=>{
                                    return(
                                      <li>
                                      <span className="loc-txt">
                                        {place}
                                      </span>
                                      <span className="view-btn-box">
                                        <Link
                                          href="#"
                                          className="btn btn-default btn-sm"
                                        >
                                          {btn}
                                        </Link>
                                      </span>
                                    </li>
                                    )
                                  })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="right-sidebar bg-white">
                          <div className="d-flex">
                            <h5 className="pl-3 pr-3 pt-2 pb-2 w-100 rounded">
                              <img
                                src={noIcn}
                                alt=""
                                className="w-10 mr-3"
                              />
                              Choose Number type
                            </h5>
                          </div>
                          <div className="sidebar-content pt-2 pl-3 pr-3 pb-3">
                            <div className="phone-dir-selects">
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" /> USA /
                                  Canada Toll Free
                                </label>
                                <select className="form-control">
                                  <option>
                                    USA/Canada Toll Free ( True 800 )
                                  </option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" /> USA Local
                                </label>
                                <select className="form-control">
                                  <option>USA, Alabama, Birmingham</option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" />{" "}
                                  International
                                </label>
                                <select className="form-control">
                                  <option>Albania - Tirana</option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <Link
                                  href="#"
                                  className="btn btn-labeled btn-number pt-2 pb-2 mr-3 text-uppercase"
                                >
                                  <img
                                    src={noICN}
                                    alt=""
                                    className="mr-2"
                                  />{" "}
                                  View Available Numbers
                                </Link>
                              </div>
                              <div className="form-group">
                                <Link href="#">RATE & INFORMATION</Link>
                              </div>
                            </div>
                            <div className="advanced-feats mt-4 mb-4">
                              <h4>Advanced Features</h4>
                              <div className="adv-feat-lists">
                                <div className="feats-box">
                                  <span className="icn-cont">
                                    <i
                                      className="fa fa-list-ol"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="feat-txt">
                                    International Numbers
                                  </span>
                                </div>
                                {Features.map(({img,text})=>{
                                  return(
                                    <div className="feats-box">
                                  <span className="icn-cont">
                                    <img
                                      src={img}
                                      alt=""
                                    />
                                  </span>
                                  <span className="feat-txt">
                                    {text}
                                  </span>
                                </div>

                                  )
                                })}
                              </div>
                              <h5>
                                <Link href="#">
                                  More Features...
                                  <i
                                    className="fa fa-angle-double-right"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </h5>
                            </div>
                            <div className="_assist mb-4">
                              <h4>Need Assistance?</h4>
                              <div className="contact-btn">
                                <Link href="#">
                                  <span className="cnt_txt">
                                    <img src={mailIcn} alt="" />{" "}
                                    Contact Us!
                                  </span>
                                  <span className="ph-icn">
                                    <img src={phnRcv} alt="" />
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <form action="" className="ask-text pl-2 pr-2">
                              <h2 className="" style={{fontSize: "17px"}}>
                                Ask us a question or tell us what you think.
                              </h2>

                              <textarea
                                name=""
                                id=""
                                rows="4"
                                cols="18"
                                className="p-3 form-control"
                              ></textarea>

                              <div className="form-group mt-4">
                                <label>
                                  <strong>Email Address:</strong>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Info@forwarding.com"
                                />
                              </div>
                              <button
                                type="button"
                                className="btn btn-orange px-3 mt-2"
                              >
                                <i
                                  className="fa fa-paper-plane"
                                  aria-hidden="true"
                                ></i>{" "}
                                Send question / comment
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                    {vanity &&
                  <div
                    className="tab-pane fade shadow rounded bg-white show active px-4 p-5"
                    id="v-pills-vanity"
                    role="tabpanel"
                    aria-labelledby="v-pills-vanity-tab"
                  >
                    <h3>Get a Vanity Phone Number</h3>
                    <div className="row mt-4">
                      <div className="col-12 col-xl-9 mb-5">
                        <div className="card shadow-lg mb-4">
                          <div className="card-body">
                            <h5>
                              North America Toll Free Numbers (USA, Canada,
                              Puerto Rico)
                            </h5>
                            <div className="small-devider"></div>
                            <div className="phone-nos-list">
                            {VirtualPhNo.map(({text,btn},index)=>{
                              return(
                                <div className="phone_number">
                                <span className="_ph_txt">
                                 {text}
                                </span>
                                <Link href="#" className={((index+1)%2==0)?"btn btn-dark":"btn btn-orange"}>
                                  {btn}
                                  <i className="fa fa-arrow-right"></i>
                                </Link>
                              </div>

                              )
                            })}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-4">
                          <div className="col-12 col-lg-6">
                            <div className="card shadow-sm">
                              <div className="card-body">
                                <div className="card-title">
                                  <h5>USA Local Phone Numbers</h5>
                                  <div className="small-devider"></div>
                                </div>
                                <div className="ph_nos_list_cont">
                                <ul>
                                  {UsaLocalNumbers.map(({place,btn})=>{
                                    return(
                                      <li>
                                      <span className="loc-txt">
                                        {place}
                                      </span>
                                      <span className="view-btn-box">
                                        <Link
                                          href="#"
                                          className="btn btn-default btn-sm"
                                        >
                                         {btn}
                                        </Link>
                                      </span>
                                    </li>
                                    )
                                  })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="card shadow-sm">
                              <div className="card-body">
                                <div className="card-title">
                                  <h5>International Phone Numbers</h5>
                                  <div className="small-devider"></div>
                                </div>
                                <div className="ph_nos_list_cont">
                                <ul>
                                  {InternationalNumbers.map(({place,btn})=>{
                                    return(
                                      <li>
                                      <span className="loc-txt">
                                        {place}
                                      </span>
                                      <span className="view-btn-box">
                                        <Link
                                          href="#"
                                          className="btn btn-default btn-sm"
                                        >
                                          {btn}
                                        </Link>
                                      </span>
                                    </li>
                                    )
                                  })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="right-sidebar bg-white">
                          <div className="d-flex">
                            <h5 className="pl-3 pr-3 pt-2 pb-2 w-100 rounded">
                              <img
                                src={noIcn}
                                alt=""
                                className="w-10 mr-3"
                              />
                              Choose Number type
                            </h5>
                          </div>
                          <div className="sidebar-content pt-2 pl-3 pr-3 pb-3">
                            <div className="phone-dir-selects">
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" /> USA /
                                  Canada Toll Free
                                </label>
                                <select className="form-control">
                                  <option>
                                    USA/Canada Toll Free ( True 800 )
                                  </option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" /> USA Local
                                </label>
                                <select className="form-control">
                                  <option>USA, Alabama, Birmingham</option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label>
                                  <input type="radio" name="ph-dir" />{" "}
                                  International
                                </label>
                                <select className="form-control">
                                  <option>Albania - Tirana</option>
                                  <option>123135464</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <Link
                                  href="#"
                                  className="btn btn-labeled btn-number pt-2 pb-2 mr-3 text-uppercase"
                                >
                                  <img
                                    src={noICN}
                                    alt=""
                                    className="mr-2"
                                  />
                                  View Available Numbers
                                </Link>
                              </div>
                              <div className="form-group">
                                <Link href="#">RATE & INFORMATION</Link>
                              </div>
                            </div>
                            <div className="advanced-feats mt-4 mb-4">
                              <h4>Advanced Features</h4>
                              <div className="adv-feat-lists">
                                <div className="feats-box">
                                  <span className="icn-cont">
                                    <i
                                      className="fa fa-list-ol"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="feat-txt">
                                    International Numbers
                                  </span>
                                </div>
                                {Features.map(({img,text})=>{
                                  return(
                                    <div className="feats-box">
                                  <span className="icn-cont">
                                    <img
                                      src={img}
                                      alt=""
                                    />
                                  </span>
                                  <span className="feat-txt">
                                    {text}
                                  </span>
                                </div>

                                  )
                                })}
                              </div>
                              <h5>
                                <Link href="#">
                                  More Features...
                                  <i
                                    className="fa fa-angle-double-right"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </h5>
                            </div>
                            <div className="_assist mb-4">
                              <h4>Need Assistance?</h4>
                              <div className="contact-btn">
                                <Link href="#">
                                  <span className="cnt_txt">
                                    <img src={mailIcn} alt="" />{" "}
                                    Contact Us!
                                  </span>
                                  <span className="ph-icn">
                                    <img src={phnRcv} alt="" />
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <form action="" class="ask-text pl-2 pr-2">
                              <h2 className="" style={{fontSize: "17px"}}>
                                Ask us a question or tell us what you think.
                              </h2>

                              <textarea
                                name=""
                                id=""
                                rows="4"
                                cols="18"
                                className="p-3 form-control"
                              ></textarea>

                              <div className="form-group mt-4">
                                <label>
                                  <strong>Email Address:</strong>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Info@forwarding.com"
                                />
                              </div>
                              <button
                                type="button"
                                className="btn btn-orange px-3 mt-2"
                              >
                                <i
                                  className="fa fa-paper-plane"
                                  aria-hidden="true"
                                ></i>{" "}
                                Send question / comment
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
  );
};

export default GetNewNumber;
